import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {
  BentoAlertItemOptions,
  BentoModalConfirmationCloseReason,
  BentoModalConfirmationService,
  BentoToastService,
} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {BackendTokenClaims} from '../../../models/tokenResponse';
import {AuthService} from '../../../services/auth/auth.service';
import {HttpParams} from '@angular/common/http';
import CurrentPerformancePeerGroupModel from 'src/app/core/models/currentperformancepeergroup.model';
import * as wjcGrid from '@grapecity/wijmo.grid';
import CreateViewModel from 'src/app/core/models/create-view.model';
import {Role} from 'src/app/shared/enums';
import $ from 'jquery';

@Component({
  selector: 'app-current-performance-peer-groups',
  templateUrl: './current-performance-peer-groups.component.html',
})
export class CurrentPerformancePeerGroupsComponent implements OnInit {
  @Input() isCanFirm: boolean;
  alerts: BentoAlertItemOptions[] = [];
  successMsg: string;
  errorMessage: any;
  isGetDataLoading = false;
  firmId: number;
  offices = {};
  practiceGroups = {};
  performanceCriteria = {};
  performancePeerGroups: CurrentPerformancePeerGroupModel[] = [];
  closeResult;
  hideNumberOfFirmAndPreviewButton: boolean = false;

  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;

  @Input() isPPGSaved: boolean;
  @Output() handleDeletePPG = new EventEmitter<boolean>();

  constructor(
    private service: BaseService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: BentoModalConfirmationService
  ) {}

  ngOnInit(): void {
    this.manageNumberOfFirmAndPreviewButton();
    this.getFirmID();
    this.getPerformancePG(this.firmId);
  }

  gridInitialized(flexGrid) {
    flexGrid.rows.defaultSize = 56;
    flexGrid.columnHeaders.rows.defaultSize = 40;
    this.flexGrid = flexGrid;
  }

  getFirmID() {
    if (this.route.snapshot.params.id) {
      this.firmId = Number(this.route.snapshot.params.id);
    } else {
      const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
      this.firmId =
        backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.firmID
          ? backendTokenClaims.userDDO.firmID
          : 0;
    }
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }

  getPerformancePG(firmId) {
    let params = new HttpParams();
    params = params.set('firmId', firmId);
    this.isGetDataLoading = true;
    this.service.get(environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/user?', params).subscribe(
      (result) => {
        this.performancePeerGroups = result;
        //convert list to coma seperated string
        this.listToString(this.performancePeerGroups);
        this.isGetDataLoading = false;
      },
      (error) => {
        this.isGetDataLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong. Please try again.',
          closeable: true,
        });
      }
    );
  }

  listToString(dataPPG) {
    //convert list to coma seperated string
    dataPPG.forEach((element) => {
      if (element.criteria) {
        element.criteria.forEach((item) => {
          if (this.performanceCriteria[element.peerLabel])
            this.performanceCriteria[element.peerLabel] = this.performanceCriteria[element.peerLabel] + ', ' + item;
          else this.performanceCriteria[element.peerLabel] = item;
        });
      }

      if (element.practiceGroups) {
        element.practiceGroups.forEach((item) => {
          if (this.practiceGroups[element.peerLabel])
            this.practiceGroups[element.peerLabel] = this.practiceGroups[element.peerLabel] + ', ' + item;
          else this.practiceGroups[element.peerLabel] = item;
        });
      }

      if (element.offices) {
        element.offices.forEach((item) => {
          if (this.offices[element.peerLabel])
            this.offices[element.peerLabel] = this.offices[element.peerLabel] + ', ' + item;
          else this.offices[element.peerLabel] = item;
        });
      }
    });
  }

  removePPG(item) {
    setTimeout(function () {
    $('.bui-modal-confirmation .modal-footer').addClass("modal-common-action-btns-footer");
    $('.bui-modal-confirmation .modal-footer').html($('.bui-modal-confirmation .modal-footer').find('button').get().reverse());
    }, 0);
    this.modalService
      .open('Are you sure you want to delete the performace peer group ' + item.peerLabel + '?', {
        titleText: 'Delete Performance Peer Group',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        closeButtonText: 'Close',
        buttonType: 'danger',
      })
      .result.then(
        (result: BentoModalConfirmationCloseReason) => {
          this.closeResult = `Closed with: ${result}`;

          if (result === BentoModalConfirmationCloseReason.Confirm) {
            this.deletePPG(item);
          }
        },
        (reason) => {
          this.closeResult = `Dismissed ${reason}`;
        }
      );
  }

  deletePPG(item) {
    let params = new HttpParams();
    params = params.set('firmId', this.firmId.toString());
    this.isGetDataLoading = true;
    this.service
      .delete(environment.FIAdminBaseEndpoint + 'v1/peergroup/performance/' + item.peerId + '?', params)
      .subscribe(
        (result) => {
          this.handleDeletePPG.emit(true);
          this.performancePeerGroups = this.performancePeerGroups.filter((element) => element.peerId !== item.peerId);
          this.successMsg = '"' + item.peerLabel + '" performance peer group was removed successfully.';
          this.flexGrid.refresh();
          this.alerts.push({
            type: 'success',
            msg: this.successMsg,
            timeout: 2500,
            closeable: true,
          });
          this.isGetDataLoading = false;
        },
        (error) => {
          this.isGetDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }
  manageNumberOfFirmAndPreviewButton() {
    if (this.isCanFirm && !this.isTAdmin) {
      this.hideNumberOfFirmAndPreviewButton = true;
    } else {
      this.hideNumberOfFirmAndPreviewButton = false;
    }
  }
}
