import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TkFilterModel} from 'src/app/core/models/tk-filter.model';
import {TkHistoryRequest} from 'src/app/core/models/tk-history-request.model';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import {BentoAlertItemOptions, BentoPaginationComponent} from '@bento/bento-ng/lib';

import {ActivatedRoute, Router} from '@angular/router';
import {GlobalHeaderServiceService} from '../../../global-header/global-header-service.service';
import {BehaviorSubject} from 'rxjs';
import {GlExtractFilterModel} from 'src/app/core/models/gl-extract-filter.model';
import {GlExtractRequest} from 'src/app/core/models/gl-extract-request.model';
import {FiscalPeriod} from 'src/app/core/models/fiscal-period.model';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {PeerCheckServiceService} from '../../../peer-check/peer-check/peer-check-service.service';
import {GlobalService} from 'src/app/core/services/global/global.service';

@Component({
  selector: 'app-gl-extract',
  templateUrl: './gl-extract.component.html',
  styleUrls: ['./gl-extract.component.scss'],
})
export class GlExtractComponent implements OnInit {
  alerts: BentoAlertItemOptions[] = [];
  isDataLoading = false;
  actionDropdownItems: {name: string; isDisabled: boolean}[];
  isFilterOpen = false;
  dropdownItems = [];
  selectBinding = 'selected';
  glAccounts: string[];
  selectedRecord = [];
  pmDataKeyValue: any = {};
  isMainBusyLoaderBusy = false;
  enableClearFilter = false;
  totalCount: any;
  tkFilterData: GlExtractFilterModel = new GlExtractFilterModel();
  fiscalPeriodModel: FiscalPeriod = new FiscalPeriod();
  fiscalDateRange = [];
  isDataModified = false;

  firmId: any;
  tkHistoryRecordRequest: GlExtractRequest = new GlExtractRequest();
  tkHistoryFilterData: GlExtractRequest = new GlExtractRequest();
  resetTKFilterFields: GlExtractRequest = new GlExtractRequest();
  isFilterReset = false;
  validations: {tkFilterFte: {pattern: string}};
  isFilterButtonDisabled = false;
  tkFilterFteVal = '(([0.0])|([0].*)|([0].[0-9][0-9])|([1.0]))';
  isColumnFreezed = false;
  fromData: any;
  toDate: any;
  successMsg: string;

  pageInfo: any = {
    infoText: '_START_ to _END_ of _MAX_ Users',
    infoPageText: '_PAGE_ of _PAGES_',
    goText: 'Go',
    pageSize: 10,
  };
  CHECKBOX_SELECTED = 1;
  CHECKBOX_UNSELECTED = 2;

  displayColumnsArray = ['pmAccount', 'period', 'firmAccount', 'override', 'office', 'department', 'realloc'];
  headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
  CHECKBOX_INDETERMINATE = 3;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjcGrid.FlexGrid;
  @ViewChild('pagination', {static: true}) pagination: BentoPaginationComponent;
  @ViewChild('filter', {static: true}) gridFilter: wjcGridFilter.FlexGridFilter;
  selectedInquiry: any;
  inquirySubscriptions: any;
  data: wjcCore.CollectionView;

  glData: any;

  constructor(
    private globalHeaderService: GlobalHeaderServiceService,
    private _peerCheckService: PeerCheckServiceService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private service: BaseService,
    private router: Router,
    private globalservice: GlobalService
  ) {
    this.data = new wjcCore.CollectionView(this.glData);
    this.data.pageSize = this.pageInfo.pageSize;
    this.actionDropdownItems = [
      {name: 'Export selected', isDisabled: false},
      {name: 'Import', isDisabled: false},
    ];

    this.validations = {
      tkFilterFte: {
        pattern: 'Error: Value should be between 0 to 1',
      },
    };
  }

  onPeriodChange() {
    const index = this.displayColumnsArray.indexOf('period');
    if (!this.tkFilterData.isPeriodVisible) {
      this.displayColumnsArray.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArray.push('period');
      }
    }
  }

  onPmAccountChange() {
    const index = this.displayColumnsArray.indexOf('pmAccount');
    if (!this.tkFilterData.isPmAccountVisible) {
      this.displayColumnsArray.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArray.push('pmAccount');
      }
    }
  }

  onFirmAcctChange() {
    const index = this.displayColumnsArray.indexOf('firmAccount');
    if (!this.tkFilterData.isFirmActVisible) {
      this.displayColumnsArray.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArray.push('firmAccount');
      }
    }
    const index1 = this.displayColumnsArray.indexOf('override');
    if (!this.tkFilterData.isFirmActVisible) {
      if (index1 != -1) {
        this.displayColumnsArray.splice(index1, 1);
      }
    }
  }

  onOverrideChange() {
    const index = this.displayColumnsArray.indexOf('override');
    if (!this.tkFilterData.isOverRideVisible) {
      this.displayColumnsArray.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArray.push('override');
      }
    }
  }
  onOfficeChange() {
    const index = this.displayColumnsArray.indexOf('office');
    if (!this.tkFilterData.isOfficeVisible) {
      this.displayColumnsArray.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArray.push('office');
      }
    }
  }

  onDeptChange() {
    const index = this.displayColumnsArray.indexOf('department');

    if (!this.tkFilterData.isDeptVisible) {
      this.displayColumnsArray.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArray.push('department');
      }
    }
    const index1 = this.displayColumnsArray.indexOf('override');
    if (!this.tkFilterData.isDeptVisible) {
      if (index1 != -1) {
        this.displayColumnsArray.splice(index1, 1);
      }
    }
  }

  onReallocChange() {
    const index = this.displayColumnsArray.indexOf('realloc');
    if (!this.tkFilterData.isReallocVisible) {
      this.displayColumnsArray.splice(index, 1);
    } else {
      if (index == -1) {
        this.displayColumnsArray.push('realloc');
      }
    }
  }
  private get getfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (this.firmId) return this.firmId;
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  async ngOnInit() {
    //Filter Body scroll method call
    var filterbodyId="FilterPanel-body-scroll-gl";
    this._peerCheckService.filterBodyMaxheightScoll(filterbodyId);

    this._peerCheckService.setBalancingValue({selected: 'Default', value: ''});
    this.route.params.subscribe((val) => {
      if (val && val.id) {
        this.firmId = val.id;
      } else {
        this.firmId = this.getfirmId;
      }
      if (this._peerCheckService.isValidMappingFirm(this.firmId)) {
        this.enableClearFilter = false;
        this.getInquiryProductionExtractFilterPanelPmAccount();
      }
    });

    this.tkHistoryRecordRequest.displayColumns = [
      'pmAccount',
      'period',
      'firmAccount',
      'override',
      'office',
      'department',
      'realloc',
    ];
  }

  ngOnDestroy(): void {
    if (this.inquirySubscriptions) {
      this.inquirySubscriptions.unsubscribe();
    }
  }
  generateRequestBody() {
    this.tkHistoryRecordRequest.displayColumns = this.displayColumnsArray;
    return this.tkHistoryRecordRequest;
  }

  initFilter() {
    this.gridFilter.showSortButtons = false;
  }

  // Pagination Size Changed Event Handler
  onPageSizeChanged(size) {
    this.data.pageSize = size;
    this.tkHistoryRecordRequest.size = size;
    this.pageInfo.pageSize = size;
    this.onCheckBoxChange();
  }

  // Pagination Page Changed Event Handler
  onPageChanged(page) {
    this.data.moveToPage(page - 1);
    this.tkHistoryRecordRequest.page = page;
    this.onCheckBoxChange();
    this.getGlExtractData();
  }

  sortGrid(headerName: string, curSort: any) {
    const currentSort = curSort.currentSort == '+' ? 'ASC' : 'DESC';
    this.tkHistoryRecordRequest.sortColumns = [headerName];
    this.tkHistoryRecordRequest.sortDir = currentSort;
    this.getGlExtractData(null, true, {
      headerName: headerName,
      curSort: curSort.currentSort == '+' ? true : false,
    });
  }

  onFilterChange() {
    this.enableClearFilter = true;
  }

  getGlExtractData(isFilter: boolean = false, isSorting: boolean = false, sortObj: any = {}, isReset: boolean = false) {
    if (!this.tkHistoryRecordRequest.accountType && !this.tkHistoryRecordRequest.accountId) {
      this.tkHistoryRecordRequest.accountType = 'P';
    }
    const requestBody = this.generateRequestBody();

    if (isFilter) {
      this.enableClearFilter = true;
      this.isFilterOpen = false;
    }
    if (isReset) {
      this.data.moveToPage(0);

      this.tkHistoryRecordRequest.page = 1;
      this.pagination.page = 1;
    }

    this.service
      .post(environment.FIClientBaseEndpoint + 'v1/inquiry/GLExtract/' + this.firmId, requestBody)
      .subscribe((result) => {
        this.glData = result.details;
        for (let i = 0; i < this.glData.length; i++) {
          this.glData[i].period += '';
        }
        if (this.glData != null) {
          this.data = new wjcCore.CollectionView(this.glData);
        } else {
          this.data = new wjcCore.CollectionView();
        }
        if (isSorting) {
          //this.flexGrid.itemsSource = this.tkHistoryRecordResponse;
          //this.gridData = this.tkHistoryRecordResponse.details;

          const sortDir = new wjcCore.SortDescription(sortObj.headerName, sortObj.curSort);

          this.data.sortDescriptions.push(sortDir);
        }
        this.data.pageSize = this.pageInfo.pageSize;
        this.totalCount = result.totalCount;
        this.globalservice.addPaginationAdditionalInfo();
      });
  }

  dropDownOnSelectChange(name) {
    // Write code to perform an action on selected option from action dropdown.
  }

  onClickFilter(newValue: boolean) {
    if (this.isFilterOpen === newValue) {
      this.isFilterOpen = false;
    } else {
      this.isFilterOpen = newValue;
    }
  }

  onFiscalPeriodChange(fiscalData: any, shouldRetrieveData: boolean = false) {
    this.fiscalPeriodModel = JSON.parse(fiscalData);
    if (
      Number(this.fiscalPeriodModel.from_date) > Number(this.fiscalPeriodModel.to_date) &&
      this.fiscalPeriodModel.to_date != null
    ) {
      this.isFilterButtonDisabled = true;
    } else {
      this.isFilterButtonDisabled = false;
    }
    if (shouldRetrieveData) {
      this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.to_date;
      this.fromData = this.fiscalPeriodModel.to_date;
      this.toDate = this.fiscalPeriodModel.to_date;
    } else {
      this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
    }
    this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;

    if (shouldRetrieveData) {
      this.getGlExtractData();
    }
  }

  onBentoAlertOccured(alerts: BentoAlertItemOptions[]) {
    this.alerts = [...this.alerts, ...alerts];
  }

  validateNumber(v: any) {
    if (v.value == '') v.value = v.value.slice(0, -1);
  }

  validateNumberWith(e) {
    if (e.keyCode == 43 || e.keyCode == 45 || e.keyCode == 101) {
      return false;
    }
  }
  firmActVisibleChange() {
    if (!this.tkFilterData.isFirmActVisible) {
      this.tkFilterData.isOverRideVisible = false;
    }
  }

  OnDeptVisibleChange() {
    if (!this.tkFilterData.isDeptVisible) {
      this.tkFilterData.isOverRideVisible = false;
    }
  }

  // onPmAccountSelected(pmAccounts: any[]) {
  //   this.tkHistoryRecordRequest.pmAccount = [];
  //   if (pmAccounts.length == 1) {
  //     this.tkFilterData.selectedPmAccountText = '1 pm account selected';
  //   } else if (pmAccounts.length == this.tkFilterData.pmAccount.length) {
  //     this.tkFilterData.selectedPmAccountText = 'All';
  //   } else this.tkFilterData.selectedPmAccountText = pmAccounts.length.toString() + ' pm accounts selected';
  //   for (var pmAccount of pmAccounts) {
  //     this.tkHistoryRecordRequest.pmAccount.push(pmAccount);
  //   }
  // }

  searchCompare(item: any, search: string) {
    const name = item;
    const searchStr = search.toLowerCase();
    return name.toLowerCase().indexOf(searchStr) > -1;
  }

  isAllSelected() {
    return this.headerCheckBoxMode === this.CHECKBOX_SELECTED;
  }

  isIndeterminate() {
    return this.headerCheckBoxMode === this.CHECKBOX_INDETERMINATE;
  }

  // Event Handler for Multi-Select Column Header Checkbox
  onHeaderCheckBoxChange() {
    let selected = false;
    if (this.headerCheckBoxMode !== this.CHECKBOX_SELECTED) {
      this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      selected = true;
    } else {
      this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
      selected = false;
    }
    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        this.flexGrid.rows[i].dataItem[this.selectBinding] = selected;
        this.flexGrid.rows[i].cssClass = selected ? 'row-selected' : '';
        selected
          ? this.saveSelection(this.flexGrid.rows[i].dataItem)
          : this.removeSelection(this.flexGrid.rows[i].dataItem);
      }
    }
    // this.updateActionDropdownItems();
    // this.setEditFields();
  }

  onCheckBoxChange() {
    this.headerCheckBoxMode = this.CHECKBOX_UNSELECTED;
    let count = 0;

    if (this.flexGrid.rows) {
      for (let i = 0, ttl = this.flexGrid.rows.length; i < ttl; i++) {
        if (this.flexGrid.rows[i].dataItem[this.selectBinding] === true) {
          count++;
          this.flexGrid.rows[i].cssClass = 'row-selected';
          this.saveSelection(this.flexGrid.rows[i].dataItem);
        } else {
          this.removeSelection(this.flexGrid.rows[i].dataItem);
          this.flexGrid.rows[i].cssClass = '';
        }
      }
      if (count === this.flexGrid.rows.length) {
        this.headerCheckBoxMode = this.CHECKBOX_SELECTED;
      } else if (count > 0) {
        this.headerCheckBoxMode = this.CHECKBOX_INDETERMINATE;
      }
    }
    // this.setEditFields();
    // this.updateActionDropdownItems();
  }

  saveSelection(selectedOption) {
    const index = this.selectedRecord.indexOf(selectedOption);
    if (index != -1) {
      this.selectedRecord.splice(index, 1, selectedOption);
    } else {
      this.selectedRecord.push(selectedOption);
    }
  }

  removeSelection(selectedOption) {
    const index = this.selectedRecord.indexOf(selectedOption);
    if (index != -1) {
      this.selectedRecord.splice(index, 1);
    }
  }

  gridInitialized(flexGrid) {
    if (flexGrid.rows) {
      flexGrid.rows.defaultSize = 56;
      flexGrid.columnHeaders.rows.defaultSize = 40;
    }
    this.flexGrid = flexGrid;
  }

  // Pagination Size Changed Event Handler

  // Pagination Page Changed Event Handler

  retrieveTKHistoryRecords() {}

  resetTKFilter(val: boolean = false) {
    if (val) {
      if (typeof this.resetTKFilterFields !== 'undefined') {
        const requestDisplayCols = this.tkHistoryRecordRequest.displayColumns;
        this.tkHistoryRecordRequest = JSON.parse(JSON.stringify(this.resetTKFilterFields));
        this.tkHistoryFilterData = JSON.parse(JSON.stringify(this.resetTKFilterFields));
        //this.tkFilterData = this.resetTkFilterData;
        //this.fiscalPeriodComponent.initialize();

        this.tkHistoryRecordRequest.displayColumns = requestDisplayCols;
        this.tkHistoryFilterData.displayColumns = requestDisplayCols;

        this.fiscalPeriodModel.to_date = this.toDate;
        this.fiscalPeriodModel.from_date = this.toDate;
        this.tkHistoryFilterData.pmAccount = 'P - All P&L Accounts';
        this.tkFilterData.selectedPmAccountText = '1 Pm Account selected';
        this.tkHistoryRecordRequest.fiscalPeriodFrom = this.fiscalPeriodModel.from_date;
        this.tkHistoryRecordRequest.fiscalPeriodTo = this.fiscalPeriodModel.to_date;
      }
    }
    this.isFilterReset = true;
    this.getGlExtractData(false, false, {}, true);
    if (this.isDataModified == false) {
      this.successMsg = 'Data reset successfully.';
      this.alerts.push({
        type: 'success',
        msg: this.successMsg,
        timeout: 2500,
        closeable: true,
      });
    }

    this.isFilterReset = false;
    this.flexGrid.refresh();
    this.enableClearFilter = false;
  }

  onPmAccountSelected(pmAccount: any) {
    // this.tkHistoryRecordRequest.pmAccount = [];
    // if (pmAccounts.length == 1) {
    //   this.tkFilterData.selectedPmAccountText = '1 Pm Account selected';
    // } else if (pmAccounts.length == this.tkFilterData.pmAccount.length) {
    //   this.tkFilterData.selectedPmAccountText = 'All';
    // } else this.tkFilterData.selectedPmAccountText = pmAccounts.length.toString() + ' Pm Account selected';
    // for (var pmAccount of pmAccounts) {
    //   this.tkHistoryRecordRequest.pmAccount.push(pmAccount);
    // }

    const splitArr = this.tkHistoryFilterData.pmAccount.split('-');
    const id = splitArr[0].trim();
    if (id == 'P' || id == 'B') {
      this.tkHistoryRecordRequest.accountType = id;
      this.tkHistoryRecordRequest.accountId = '';
    } else {
      this.tkHistoryRecordRequest.accountId = id;
      this.tkHistoryRecordRequest.accountType = '';
    }
  }

  getInquiryProductionExtractFilterPanelPmAccount() {
    this.service.get(environment.FIClientBaseEndpoint + 'v1/mapping/glaccount/pm', '').subscribe(
      (result) => {
        for (let obj of result) {
          this.dropdownItems.push({
            value: obj.id,
            text: obj.id + ' - ' + obj.name,
            type: obj.type,
          });
        }

        for (let obj of this.dropdownItems) {
          this.pmDataKeyValue[obj['value']] = obj['text'];
        }

        this.glAccounts = Object.values(this.pmDataKeyValue);
        this.glAccounts.splice(0, 0, 'P - All P&L Accounts');
        this.glAccounts.splice(1, 0, 'B - All Balance Sheet Accounts');

        this.tkFilterData.pmAccount = result;
        this.tkFilterData.pmAccountStream = new BehaviorSubject(
          (() => {
            const a = this.glAccounts;

            return a;
          })()
        );
        this.tkHistoryFilterData.pmAccount = 'P - All P&L Accounts';
        this.tkFilterData.selectedPmAccountText = '1 Pm Account selected';
      },
      (error) => {
        //this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );

    // this.tkFilterData.tkinitStream = new BehaviorSubject(
    //   (() => {
    //     const a = ['tkId1', 'tkId2'];
    //     this.tkFilterData.tkId = a;
    //     return a;
    //   })()
    // );
  }
}
