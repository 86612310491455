<bento-alert [items]="alerts"></bento-alert>
<main id="MainContent" tabindex="-1" class="Main" role="main">
  <header class="Main-header">
    <div class="row">
      <div class="col">
        <h1 class="Main-heading">Production Extract Inquiry</h1>
        <app-mapping-firms-details></app-mapping-firms-details>
      </div>
      <div class="col">
        <app-action-dropdown
          #actionButton
          [actionDropdownItems]="actionDropdownItems"
          (dropDownSelectChange)="dropDownOnSelectChange($event)"
          [disable]="true"
        >
        </app-action-dropdown>
      </div>
    </div>
  </header>
  <div class="Main-body FilterPanel FilterPanel--toggle FilterPanel--toggle--Modal">
    <aside
      class="FilterPanel-control FilterPanel-control-scroll FilterPanel-control--Modal"
      [ngClass]="{'is-open': isFilterOpen == true}"
    >
      <form class="FilterPanel-control--Timekeeper" name="timekeeperFilter" #timekeeperFilter="ngForm" bfmForm>
        <header class="FilterPanel-header">
          <h2 class="h3 FilterPanel-heading">Filter</h2>
          <button
            type="button"
            class="btn btn-icon"
            [attr.aria-label]="isFilterOpen ? 'Close filter panel' : 'Open filter panel'"
            [ngbTooltip]="isFilterOpen ? 'Close filter panel' : 'Open filter panel'"
            placement="right"
            (click)="onClickFilter(true)"
          >
            <i [ngClass]="'bento-icon-close-x'" aria-hidden="true"></i>
          </button>
        </header>

        <div class="FilterPanel-body" id="FilterPanel-body-scroll-pr" [bentoBusyLoader]="isMainBusyLoaderBusy">
          <ul class="FilterPanel-list">
            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPeriodVisible"
                  id="isPeriodChecked"
                  name="isPeriodChecked"
                  (ngModelChange)="onPeriodChange()"
                ></bento-checkbox>
              </div>
              <div class="FilterPanel-input" *ngIf="showInquiryMenu">
                <app-fiscal-period
                  #fiscalPeriodComponent
                  [fiscalPeriodModel]="fiscalPeriodModel"
                  [fiscalDateRange]="fiscalDateRange"
                  (selectionChanged)="onFiscalPeriodChange($event)"
                  (fiscalPeriodLoaded)="onFiscalPeriodChange($event, true)"
                  (bentoAlertOccured)="onBentoAlertOccured($event)"
                  [requestFrom]="'productionExtract'"
                ></app-fiscal-period>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPersonVisible"
                  id="isPersonChecked"
                  name="isPersonChecked"
                  (ngModelChange)="onPersonChange()"
                ></bento-checkbox>
                <label for="isPersonChecked" class="sr-only">Display Name column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="person">Name</label>
                <bento-multiselect-overlay
                  ngDefaultControl
                  name="person"
                  id="person"
                  class="FormMultiselect"
                  [(ngModel)]="tkHistoryFilterData.name"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPersonText"
                  (ngModelChange)="onPersonSelected($event)"
                  [itemsObservable]="tkFilterData.personStream"
                  [listItemTemplate]="customListPersonItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListPersonItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isTimeTypeVisible"
                  id="isTimeTypeVisible"
                  name="isTimeTypeVisible"
                  (ngModelChange)="onTimeTypeChange()"
                >
                </bento-checkbox>
                <label for="isTimeTypeVisible" class="sr-only">Display timeType column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="timeType">Time type</label>
                <bento-multiselect-overlay
                  name="timeType"
                  id="timeType"
                  class="FormMultiselect"
                  [(ngModel)]="tkHistoryFilterData.timeType"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedTimeTypeText"
                  (ngModelChange)="onTimeTypeSelected($event)"
                  [itemsObservable]="tkFilterData.timeTypeStream"
                  [listItemTemplate]="customListTimeTypeListItemTemplate"
                  [searchCompare]="searchCompare"
                  ngDefaultControl
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListTimeTypeListItemTemplate let-item="item" let-index="index">
                  <div>
                    {{ item }}
                  </div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isTitleVisible"
                  id="isTitleVisible"
                  name="isTitleVisible"
                  (ngModelChange)="onTitleChange()"
                ></bento-checkbox>
                <label for="isTitleVisible" class="sr-only">Display title column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="title">Title</label>
                <bento-multiselect-overlay
                  name="title"
                  id="title"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.firmTitle"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedFirmTitleText"
                  (ngModelChange)="onTitleSelected($event)"
                  [itemsObservable]="tkFilterData.firmTitleStream"
                  [listItemTemplate]="customListTitleItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListTitleItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isOfficeVisible"
                  id="isOfficeVisible"
                  name="isOfficeVisible"
                  ngDefaultControl
                  (ngModelChange)="onOfficeChange()"
                ></bento-checkbox>
                <label for="isOfficeVisible" class="sr-only">Display office column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="office">Office</label>
                <bento-multiselect-overlay
                  name="office"
                  id="office"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.firmOffice"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedFirmOfficeText"
                  (ngModelChange)="onOfficeSelected($event)"
                  [itemsObservable]="tkFilterData.firmOfficeStream"
                  [listItemTemplate]="customListOfficeItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListOfficeItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isDeptVisible"
                  id="isDeptVisible"
                  name="isDeptVisible"
                  ngDefaultControl
                  (ngModelChange)="onDeptChange()"
                ></bento-checkbox>
                <label for="isDeptVisible" class="sr-only">Display department column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="department">Department</label>
                <bento-multiselect-overlay
                  name="department"
                  id="department"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.department"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedDeptText"
                  [overlayMaxHeight]="400"
                  (ngModelChange)="onDeptSelected($event)"
                  [itemsObservable]="tkFilterData.departmentStream"
                  [listItemTemplate]="customListDeptItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListDeptItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isSectionVisible"
                  id="isSectionVisible"
                  name="isSectionVisible"
                  ngDefaultControl
                  (ngModelChange)="onSectionChange()"
                ></bento-checkbox>
                <label for="isSectionVisible" class="sr-only">Display section column</label>
              </div>
              <div class="FilterPanel-input">
                <!-- TODO: Placeholder to hook with services API -->
                <label for="section">Section</label>
                <select id="section" bentoSelect>
                  <option>Item 1</option>
                  <option>Item 2</option>
                  <option>Item 3</option>
                  <option>Item 4</option>
                </select>
              </div>
            </li>

            <li class="FilterPanel-listItem" *ngIf="fmsType != '4' && fmsType != '5' && fmsType != '3'">
              <div class="FilterPanel-display">
                <bento-checkbox
                  [(ngModel)]="tkFilterData.isUdfVisible"
                  id="isUdfVisible"
                  name="isUdfVisible"
                  ngDefaultControl
                  (ngModelChange)="onUdfChange()"
                ></bento-checkbox>
                <label for="isUdfVisible" class="sr-only">Display UDF column</label>
              </div>
              <div class="FilterPanel-input">
                <!-- TODO: Placeholder to hook with services API -->
                <label for="UDF">UDF</label>
                <select id="UDF" bentoSelect>
                  <option>Item 1</option>
                  <option>Item 2</option>
                  <option>Item 3</option>
                  <option>Item 4</option>
                </select>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPeerOfficeVisible"
                  id="isPmOfficeVisible"
                  name="isPmOfficeVisible"
                  (ngModelChange)="onPeerOfficeChange()"
                ></bento-checkbox>
                <label for="isPmOfficeVisible" class="sr-only">Display office column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="peeroffice">Peer office</label>
                <bento-multiselect-overlay
                  name="peeroffice"
                  id="peeroffice"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.pmOffice"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPeerOfficeText"
                  (ngModelChange)="onPeerOfficeSelected($event)"
                  [itemsObservable]="tkFilterData.peerOfficeStream"
                  [listItemTemplate]="customListPmOfficeItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListPmOfficeItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPeerPracticeVisible"
                  id="isPeerPracticeVisible"
                  name="isPeerPracticeVisible"
                  (ngModelChange)="onPeerPracticeChange()"
                ></bento-checkbox>
                <label for="isPeerPracticeVisible" class="sr-only">Display practice column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="peerpractice">Peer practice</label>
                <bento-multiselect-overlay
                  name="peerpractice"
                  id="peerpractice"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.pmPractice"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPeerPracticeText"
                  (ngModelChange)="onPeerPracticeSelected($event)"
                  [itemsObservable]="tkFilterData.peerPracticeStream"
                  [listItemTemplate]="customListPmPracticeItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListPmPracticeItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display">
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isPmTitleVisible"
                  id="isPmTitleVisible"
                  name="isPmTitleVisible"
                  (ngModelChange)="onPeerTitleChange()"
                ></bento-checkbox>
                <label for="isPmTitleVisible" class="sr-only">Display title column</label>
              </div>
              <div class="FilterPanel-input">
                <label for="title">Peer title</label>
                <bento-multiselect-overlay
                  name="title"
                  id="title"
                  class="FormMultiselect"
                  ngDefaultControl
                  [(ngModel)]="tkHistoryFilterData.pmTitle"
                  [selectButtonText]="'All'"
                  [overlayMaxHeight]="400"
                  [editButtonText]="tkFilterData.selectedPeerTitleText"
                  (ngModelChange)="onPmTitleSelected($event)"
                  [itemsObservable]="tkFilterData.peerTitleStream"
                  [listItemTemplate]="customListPmTitleItemTemplate"
                  [searchCompare]="searchCompare"
                >
                </bento-multiselect-overlay>
                <!-- Custom list item definition -->
                <ng-template #customListPmTitleItemTemplate let-item="item" let-index="index">
                  <div>{{ item }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div class="FilterPanel-display"></div>
              <div class="FilterPanel-input">
                <label for="values">Values</label>

                <app-bento-custom-multiselect-overlay
                  name="values"
                  id="values"
                  ngDefaultControl
                  #values
                  class="FormMultiselect"
                  bfmLabel="Values"
                  bfmField
                  required
                  [overlayMaxHeight]="400"
                  [(ngModel)]="tkHistoryFilterData.values"
                  (ngModelChange)="onValueSelected($event)"
                  [itemsObservable]="tkFilterData.valuesStream"
                  [editButtonText]="tkFilterData.selectedValuesTypeText"
                  [listItemTemplate]="customListValuesItemTemplate"
                  [minSelectedItems]="1"
                  aria-labelledby="values"
                  [ariaLabelValue]="'values'"
                ></app-bento-custom-multiselect-overlay>

                <!-- Custom list item definition -->
                <ng-template #customListValuesItemTemplate let-item="item" let-index="index">
                  <div>{{ valuesObj[item.key] }}</div>
                </ng-template>
              </div>
            </li>

            <li class="FilterPanel-listItem">
              <div>
                <bento-checkbox
                  ngDefaultControl
                  [(ngModel)]="tkFilterData.isGradYear"
                  id="isGradYearVisible"
                  name="isGradYearVisible"
                  (ngModelChange)="onGradYearChange()"
                >
                </bento-checkbox>

                <label for="isTimeTypeVisible" class="sr-only">Display gradYear column</label>
              </div>
              <div>
                <label class="gradYear" for="isGradYearVisible">Grad year</label>
              </div>
            </li>
          </ul>
        </div>
        <footer class="FilterPanel-footer--Modal">
          <button type="button" role="button" (click)="resetTKFilter(true)" class="btn btn-outline-secondary">
            <span class="btn-text">Clear</span></button
          ><button
            type="submit"
            role="button"
            [disabled]="isFilterButtonDisabled || tkHistoryFilterData.values.length == 0"
            (click)="retrieveProductionExtractRecords(true)"
            class="btn btn-primary"
          >
            <span class="btn-text">Filter</span>
          </button>
        </footer>
        <!-- <div class="form-group Form-group footer">
          <div class="bento-form-buttons divClass">
            <button type="button" class="btn btn-outline-secondary" (click)="resetTKFilter(true)">
              <span class="btn-text">Clear</span>
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="isFilterButtonDisabled || tkHistoryFilterData.values.length == 0"
              (click)="retrieveProductionExtractRecords(true)"
            >
              <span class="btn-text">Filter</span>
            </button>
          </div>
        </div> -->
      </form>
    </aside>

    <div class="FilterPanel-data">
      <div class="row">
        <div class="col Filter-buttons">
          <button class="btn btn-outline-secondary" role="button" (click)="isFilterOpen = !isFilterOpen">Filter</button>
          <button class="btn-clear-filter" role="button" [disabled]="!enableClearFilter" (click)="resetTKFilter(true)">
            Clear filters
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-icon btn-sm"
            [attr.aria-label]="isColumnFreezed ? columnUnfreezeMessage : columnFreezeMessage"
            [ngbTooltip]="isColumnFreezed ? columnUnfreezeMessage : columnFreezeMessage"
            placement="right"
            (click)="isColumnFreezed = !isColumnFreezed"
          >
            <i [ngClass]="isColumnFreezed ? 'bento-icon-lock' : 'bento-icon-unlocked'" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="FilterPanel-dataGrid">
        <wj-flex-grid
          #flexGrid
          [bentoBusyLoader]="isDataLoading"
          class="DataGrid ExtractFlexWidth"
          [frozenColumns]="isColumnFreezed ? numberOfCoulmnToFreeze : 0"
          [itemsSource]="gridData"
          [stickyHeaders]="true"
          [showSort]="true"
          [showMarquee]="true"
          [readOnly]="true"
          [allowDragging]="false"
          [autoRowHeights]="true"
          [bentoBusyLoader]="isDataLoading"
          (initialized)="gridInitialized(flexGrid)"
          (sortedColumn)="sortGrid($event.getColumn().binding, $event.getColumn())"
        >
          <wj-flex-grid-filter
            #filter
            (initialized)="initFilter()"
            (filterChanged)="onFilterChange()"
          ></wj-flex-grid-filter>
          <ng-template wjFlexGridCellTemplate [cellType]="'TopLeft'" let-col="col">
            <ng-container *ngIf="col.index === 0">
              <bento-checkbox
                id="selectAll"
                [ngModel]="isAllSelected()"
                (change)="onHeaderCheckBoxChange()"
                [indeterminate]="isIndeterminate()"
              >
              </bento-checkbox>
              <label for="selectAll" class="sr-only">Select all</label>
            </ng-container>
          </ng-template>
          <ng-template wjFlexGridCellTemplate [cellType]="'RowHeader'" let-col="col" let-row="row" let-item="item">
            <ng-container *ngIf="col.index === 0">
              <bento-checkbox
                id="selectRow-{{ row.index }}"
                [ngModel]="item.selected"
                (change)="item.disabled || (item.selected = !item.selected); onCheckBoxChange()"
              ></bento-checkbox>
              <label for="selectRow-{{ row.index }}" class="sr-only">{{ item.name }}</label>
            </ng-container>
          </ng-template>
          <wj-flex-grid-column
            [header]="'Period'"
            [binding]="'period'"
            [visible]="tkFilterData.isPeriodVisible"
            [minWidth]="40"
            [width]="100"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          >
          </wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'First name'"
            [binding]="'firstName'"
            [visible]="tkFilterData.isPersonVisible && fmsType != '4' && fmsType != '5' && fmsType != '3'"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Last name'"
            [binding]="'lastName'"
            [minWidth]="50"
            [visible]="tkFilterData.isPersonVisible && fmsType != '4' && fmsType != '5' && fmsType != '3'"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Name'"
            [binding]="'firstName'"
            [visible]="tkFilterData.isPersonVisible && (fmsType == '4' || fmsType == '5' || fmsType == '3')"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'TKINIT'"
            [binding]="'tkId'"
            [visible]="tkFilterData.isPersonVisible"
            [minWidth]="40"
            [width]="100"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Time type'"
            [binding]="'timeType'"
            [visible]="tkFilterData.isTimeTypeVisible"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Title'"
            [binding]="'firmTitleDesc'"
            [visible]="tkFilterData.isTitleVisible"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Office'"
            [binding]="'firmOfficeDesc'"
            [visible]="tkFilterData.isOfficeVisible"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Department'"
            [binding]="'departmentDesc'"
            [visible]="tkFilterData.isDeptVisible"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Section'"
            [binding]="'sectionDesc'"
            [visible]="tkFilterData.isSectionVisible"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Udf'"
            [binding]="'udfDesc'"
            [visible]="tkFilterData.isUdfVisible && fmsType != '4' && fmsType != '3' && fmsType != '5'"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Peer office'"
            [binding]="'peerOfficeDesc'"
            [visible]="tkFilterData.isPeerOfficeVisible"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Peer practice'"
            [binding]="'peerPracticeDesc'"
            [visible]="tkFilterData.isPeerPracticeVisible"
            [minWidth]="40"
            [width]="300"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            [header]="'Peer title'"
            [binding]="'peerTitleDesc'"
            [visible]="tkFilterData.isPmTitleVisible"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Worked hrs'"
            [binding]="'workedHrs'"
            [visible]="showValue(valuesCodeMap['workedHrs'])"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Worked amt'"
            [binding]="'workedAmt'"
            [visible]="showValue(valuesCodeMap['workedAmt'])"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Worked rate'"
            [binding]="'workedRate'"
            [visible]="showValue(valuesCodeMap['workedRate'])"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>

          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Standard rate'"
            [binding]="'standardRate'"
            [visible]="showValue(valuesCodeMap['standardRate'])"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Billed amt'"
            [binding]="'billedAmt'"
            [visible]="showValue(valuesCodeMap['billedAmt'])"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Billed rate'"
            [binding]="'billedRate'"
            [visible]="showValue(valuesCodeMap['billedRate'])"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Collected amt'"
            [binding]="'collectedAmt'"
            [visible]="showValue(valuesCodeMap['collectedAmt'])"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Collected rate'"
            [binding]="'collectedRate'"
            [visible]="showValue(valuesCodeMap['collectedRate'])"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            cssClass="wj-cell--input"
            [header]="'Billing realization'"
            [binding]="'billingRealizePcnt'"
            [visible]="showValue(valuesCodeMap['billingRealization'])"
            [minWidth]="30"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Collect realization'"
            [binding]="'collectedRealizePcnt'"
            [visible]="showValue(valuesCodeMap['collectionRealization'])"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Writeoff amt'"
            [binding]="'writeOffAmt'"
            [visible]="showValue(valuesCodeMap['writeoffAmt'])"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'FTE value'"
            [binding]="'fteValue'"
            [visible]="showValue(valuesCodeMap['fteValue'])"
            [minWidth]="60"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          ></wj-flex-grid-column>
          <wj-flex-grid-column
            [header]="'Grad Year'"
            [binding]="'gradYear'"
            [visible]="tkFilterData.isGradYear"
            [minWidth]="40"
            [width]="200"
            [isReadOnly]="true"
            [wordWrap]="true"
            [align]="'left'"
          >
          </wj-flex-grid-column>
        </wj-flex-grid>
        <!-- BENTO PAGINATION -->
        <bento-pagination
          [page]="gridData.pageIndex + 1"
          [directionLinks]="true"
          [boundaryLinks]="true"
          [totalItems]="totalCount"
          [(itemsPerPage)]="gridData.pageSize"
          (itemsPerPageChange)="onPageSizeChanged($event)"
          (pageChange)="onPageChanged($event)"
        ></bento-pagination>
      </div>
    </div>
  </div>
</main>
