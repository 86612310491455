/* eslint-disable max-len */
import {Component, OnDestroy, OnInit, HostBinding} from '@angular/core';
import {BentoAlertItemOptions, BentoSideNavItem} from '@bento/bento-ng';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {DownloadService} from 'src/app/core/services/download/download.service';
import {HttpParams} from '@angular/common/http';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {AuthService} from 'src/app/core/services/auth/auth.service';
declare let TRAAC: any;

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
})
export class ResourcesComponent implements OnInit, OnDestroy {
  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  errorMessage = '';
  isLoading = false;
  alerts: BentoAlertItemOptions[] = [];
  clientReportsArr = [];
  countryArr = [];
  items: BentoSideNavItem[] = [
    {
      label: 'Reference documents',
      selected: true,
      action: () => {
        this.showHideUserTabSideNav('Reference documents');
      },
      data: {isVisible: true},
    },
    {
      label: 'User guide',
      action: () => {
        this.showHideUserTabSideNav('User guide');
      },
      data: {isVisible: false},
    },
    {
      label: 'Legal industry update',
      action: () => {
        this.showHideUserTabSideNav('Legal industry update');
      },
      data: {isVisible: false},
    },
    {
      label: 'Client reports',
      action: () => {
        this.showHideUserTabSideNav('Client reports');
      },
      collapsed: true,
      items: this.clientReportsArr,
      data: {isVisible: false},
    },
    {
      label: 'Thought leadership reports',
      action: () => {
        this.showHideUserTabSideNav('Thought leadership reports');
      },
      data: {isVisible: false},
    },
    {
      label: 'Law firm financial index',
      action: () => {
        this.showHideUserTabSideNav('Law firm financial index');
      },
      data: {isVisible: false},
    },
  ];
  resourceContent;
  selectedCountry: string;
  isQuickSightEnabled = false;
  yearForPeerMonitor = [2019, 2020, 2021];
  constructor(
    private downloadFile: DownloadService,
    private service: BaseService,
    private featureToggleService: FeatureToggleService,
    private authService: AuthService
  ) {}

  ngOnDestroy() {
    setTimeout(() => {
      var element = document.getElementById('resources');
      if (element) {
        element.classList.remove('router-link-active');
      }
    }, 0);
  }
  async ngOnInit(): Promise<void> {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /*************************************************************/
    if (this.isQuickSightEnabled) {
      TRAAC.track(TRAAC.keyValue('Resources click', {email: this.authService.getUserEmail()}).build());
    }
    this.service.get(environment.FIClientBaseEndpoint + 'v1/storage/listFiles', '').subscribe(
      (content) => {
        this.isLoading = true;
        this.resourceContent = content;
        this.clientReports();
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );

    setTimeout(() => {
      var element = document.getElementById('resources');
      if (element) {
        element.classList.add('router-link-active');
      }
    }, 0);
  }

  clientReports() {
    var itemObj;
    var items = [];
    this.resourceContent.clientReport.forEach((element) => {
      this.countryArr.push(element.country);
      itemObj = {
        label: element.country,
        selected: false,
        data: {isVisible: false},
        action: () => {
          this.showHideUserTabSideNav(element.country);
        },
      };

      items.push(itemObj);
      this.items.forEach((element) => {
        if (element.label === 'Client reports') {
          element.items = items;
        }
      });
    });
  }
  showHideUserTabSideNav(label: string) {
    var isClient = false;
    this.items.forEach((element: BentoSideNavItem) => {
      if (element.label === label) {
        switch (element.label) {
          case 'Client reports':
            element.data.isVisible = true;
            this.selectedCountry = element.items[0].label;
            isClient = true;
            element.items[0].data.isVisible = true;
            element.items[0].selected = true;
            element.collapsed = false;
            element.items.forEach((item, index) => {
              if (item[index] > 0) {
                element.items[index].data.isVisible = false;
                element.items[index].selected = false;
              }
            });
            break;
          default:
            element.data.isVisible = true;
            break;
        }
      } else {
        element.data.isVisible = false;
      }

      this.countryArr.forEach((country, index) => {
        if (country == label) {
          this.selectedCountry = label;
          this.items[3].items[index].data.isVisible = true;
          this.items[3].items[index].selected = true;
        } else {
          if (!isClient) {
            this.items[3].items[index].selected = false;
            this.items[3].items[index].data.isVisible = false;
          }
        }
      });
    });
  }
  downloadResourceFile(blobName: string, fileName: string, extension: string) {
    let params = new HttpParams();
    params = params.set('blobName', blobName);
    params = params.set('fileName', fileName);
    this.service.downloadExcel(environment.FIClientBaseEndpoint + 'v1/storage/downloadFile?' + params).subscribe(
      (response) => {
        this.downloadFile.downloadWithExtension(
          response.body,
          response.headers.get('content-disposition').split('=')[1],
          extension
        );
      },
      (error) => {
        this.errorMessage = error.error;
        this.alerts.push({
          type: 'warning',
          msg: 'Something went wrong, please try again.',
          closeable: true,
        });
      }
    );
  }

  asIsOrder(a, b) {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  }
}
