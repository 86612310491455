import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {BentoComboboxOptions} from '@bento/bento-ng';
import {BehaviorSubject, Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SaveViewComponent} from './save-view/save-view.component';
import {BentoAlertItemOptions} from '@bento/bento-ng';
import CreateViewModel, {
  FixedTimeRange,
  Metric,
  PeerGroupInfo,
  ProcessedBaseTableData,
  ProcessedMainData,
  SaveView,
  ViewDetails,
} from '../../../models/create-view.model';
import * as wjGrid from '@grapecity/wijmo.grid';
import {BfmFieldEvent} from '@bento/bfm-ng';
import {BackendTokenClaims} from 'src/app/core/models/tokenResponse';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {BaseService} from 'src/app/core/services/base/base.service';
import {environment} from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';
import {_fixedSizeVirtualScrollStrategyFactory} from '@angular/cdk/scrolling';
import {PeercheckMessageHandlerService} from 'src/app/core/services/peercheck-message-handler/peercheck-message-handler.service';
import {WjFlexChart} from '@grapecity/wijmo.angular2.chart';
import {WjTransposedGrid} from '@grapecity/wijmo.angular2.grid.transposed';
import {NgForm} from '@angular/forms';
import FirmBasicsModel from '../../../models/firm-basics.model';
import {PeerCheckServiceService} from '../peer-check/peer-check-service.service';
import PeerCheckViewColumns from '../../../models/peerCheckViewColumns.model';
import {PivotRequestBody} from 'src/app/core/models/pivotRequestBody.model';
import {ViewDataGridComponent} from '../view-grid/view-data-grid/view-data-grid.component';
import {eventDispatcher, store} from 'src/app/core/store';
import {ActionTypes} from 'src/app/core/store/actions';
import {PerformancePeerGroupsComponent} from '../performance-peer-groups/performance-peer-groups.component';
import {FeatureToggleService} from 'src/app/core/services/feature-toggle/feature-toggle.service';
import {isJSDocThisTag} from 'typescript';
import {Role} from 'src/app/shared/enums';
declare let TRAAC: any;
import $ from 'jquery';
import {GlobalService} from 'src/app/core/services/global/global.service';
@Component({
  selector: 'app-create-view',
  templateUrl: './create-view.component.html',
})
export class CreateViewComponent implements OnInit, OnDestroy {
  createViewsModel: CreateViewModel = new CreateViewModel();
  alerts: BentoAlertItemOptions[] = [];
  isCollapsed = false;
  validatePattern = '^(0[1-9]|10|11|12)/([0-9]{4})$';
  validations: any;
  openPPG = false;
  disableDropdowns = true;
  isDataLoading = true;
  errorMessage = '';
  allowedPartnerYearTitles = [-10, 1, 2];
  allowedAssociateYearTitles = [4];
  allowedSrAssociateYearTitles = [3];
  isPartnerYear = false;
  isAssociateYear = false;
  isSrAssociateYear = false;

  filterPanelFlag: boolean;
  isFilterPanelOpen: boolean;
  firmBasicData = new FirmBasicsModel();
  pcViewCols = new PeerCheckViewColumns();
  metricReorderIdsArr = [];
  extractedYYYYMM = {
    year: null,
    month: null,
  };

  @ViewChild('listOfFirmsModal') listOfFirmsModalContent: any;
  @ViewChild('flexGrid', {static: true}) flexGrid: wjGrid.FlexGrid;
  @ViewChild('ViewGrid') viewGridComponent: ViewDataGridComponent;
  customOverlay: boolean;

  @ViewChild('createView') createViewForm: NgForm;
  @ViewChild(PerformancePeerGroupsComponent) performancePeerGroups;
  selectedTimeRange: string;
  isCopyEditRequest: boolean;
  viewDetails: ViewDetails;
  isCustomRequest: boolean;
  isInputPanelDataLoading: boolean;
  isSharedView: boolean;
  subscription: Subscription[] = [];
  isGetBasicAPICalled = false;
  isCanFirm: boolean = false;

  // Add class to parent
  @HostBinding('class.u-flexGrowCol') get ComponentClass(): boolean {
    return true;
  }
  rollingTimeRangeItemsStream: BehaviorSubject<any>;
  metricItemsStream: BehaviorSubject<any>;
  peerGroupItemsStream: BehaviorSubject<any>;
  practiceGroupItemsStream: BehaviorSubject<any>;
  officeItemsStream: BehaviorSubject<any>;
  titleItemsStream: BehaviorSubject<any>;
  associateYearItemsStream: BehaviorSubject<any>;
  srAssociateYearItemsStream: BehaviorSubject<any>;
  partnerYearItemsStream: BehaviorSubject<any>;
  isQuickSightEnabled = false;

  //time range
  comboboxOptions: BentoComboboxOptions = {
    searchable: true,
    autoSelect: false,
    labelFormatter: (row) => `${row.name}`,
  };

  processedMainGridData: ProcessedMainData;
  processedBaseTableData: ProcessedBaseTableData;

  emptyGridObj = [
    {
      values: [
        {
          metrics: [
            {
              metricName: null,
              firmValue: null,
              firmValueFormat: null,
              firmDelta: null,
              firmDeltaFormat: null,
            },
          ],
        },
      ],
    },
  ];

  constructor(
    public modalService: NgbModal,
    private authService: AuthService,
    private service: BaseService,
    private route: ActivatedRoute,
    private peercheckMessageHandlerService: PeercheckMessageHandlerService,
    public _service: PeerCheckServiceService,
    private featureToggleService: FeatureToggleService,
    private globalservice: GlobalService
  ) {
    this.isInputPanelDataLoading = true;
    this.validations = {
      validatePattern: {
        pattern: 'Error:  Enter valid date format',
      },
    };

    this.createViewsModel = new CreateViewModel();
    this.isSharedView = this.peercheckMessageHandlerService.getrequestFromSharedViews;

    if (this.peercheckMessageHandlerService) {
      this.processedMainGridData = this.peercheckMessageHandlerService.processDataForMainGrid(
        this.emptyGridObj,
        this.createViewsModel
      );
    }

    let dataLoader = this._service.isDataLoadingMessage.subscribe((isDataProcessing: boolean) => {
      this.isDataLoading = isDataProcessing;
    });
    this.subscription.push(dataLoader);
  }
  ngOnDestroy(): void {
    this.peercheckMessageHandlerService.setViewDetails = null;
    this.peercheckMessageHandlerService.setIsCopyEditRequest = false;
    this.peercheckMessageHandlerService.setrequestFromSharedViews = false;
    this.peercheckMessageHandlerService.setCreateViewUserSettings = false;
    this.peercheckMessageHandlerService.setSelectedViewId = null;
    this.peercheckMessageHandlerService.setSelectedViewData = null;
    if (this.subscription && this.subscription.length) {
      this.subscription.forEach((subsc) => {
        subsc.unsubscribe();
      });
    }
  }

  async ngOnInit() {
    this.isFilterPanelOpen = true;
    this.getFilterPanelFeatureFlag();
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - QUICKSIGHT ******/
    await this.featureToggleService.getFeatureValue('QUICKSIGHT').then((val) => (this.isQuickSightEnabled = val));
    /*************************************************/
    this.getBasicDetails();
  }

  async getBasicDetails() {
    //Service will return getBasic details and firm level rules
    this.isDataLoading = true;
    this.firmBasicData = await this._service.getFirmBasicsData(this.getDefaultOrSelectedFirmId);
    this.pcViewCols = this.firmBasicData.firmLevelRules;
    if (this.firmBasicData.defaultAccess == 'CAN') {
      this.isCanFirm = true;
    }
    this.isGetBasicAPICalled = true;
    if (this.firmBasicData.yyyymm) {
      this.extractedYYYYMM.year = this.firmBasicData.yyyymm.toString().substring(0, 4);
      this.extractedYYYYMM.month = this.firmBasicData.yyyymm.toString().substring(4, 6);
    }
    this.initializeGridValues();
  }

  initializeGridValues() {
    this.createViewsModel.isUKFirm = this.firmBasicData.defaultAccess == 'UK';
    this.createViewsModel.isCANFirm = this.firmBasicData.defaultAccess == 'CAN';

    this.createViewsModel.defaultAccess = this.firmBasicData.defaultAccess;

    // fill dropdown values
    this.isCopyEditRequest = this.peercheckMessageHandlerService.getIsCopyEditRequest;
    this.isCustomRequest = this.peercheckMessageHandlerService.getIsCustomRequest;
    this.viewDetails = this.peercheckMessageHandlerService.getViewDetails;

    let selectedViewId = this.peercheckMessageHandlerService.getSelectedViewId;

    if (this.isCopyEditRequest && selectedViewId && !this.viewDetails) {
      this.getViewDetails(selectedViewId);
    } else {
      this.populateInputValues();
    }
    this.customOverlay = environment.customOverlay;
    this.selectedTimeRange = this.createViewsModel.timeRangeSelected;

    if (this.isQuickSightEnabled && !this.isCopyEditRequest && !this.isCustomRequest) {
      TRAAC.track(TRAAC.keyValue('Create a view click', {email: this.authService.getUserEmail()}).build());
    }
  }

  private get getDefaultfirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    if (backendTokenClaims.userDDO) {
      return backendTokenClaims.userDDO.firmID;
    }
  }

  // getDefaultOrSelectedFirmId() should return the selected firmID OR firmID of logged in user.
  private get getDefaultOrSelectedFirmId(): number {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims.userDDO && !this.route.snapshot.params.id
      ? backendTokenClaims.userDDO.firmID
      : parseInt(this.route.snapshot.paramMap.get('id'));
  }

  populateInputValues(refreshFromPPG: boolean = false) {
    this.isDataLoading = true;
    this.isInputPanelDataLoading = true;
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/view/values/' + this.getDefaultOrSelectedFirmId, '')
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.globalservice.skipFocusTwiceCombobox();
          if (result) {
            this.viewGridComponent.availableInputValues = result;
            if (result.metrics && result.metrics.length)
              this.metricItemsStream = new BehaviorSubject(this.getMetricGroup(result.metrics));
            if (result.peerGroups && result.peerGroups.length)
              this.peerGroupItemsStream = new BehaviorSubject(result.peerGroups);
            if (result.offices && result.offices.length) this.officeItemsStream = new BehaviorSubject(result.offices);
            if (result.titles && result.titles.length) {
              this.titleItemsStream = new BehaviorSubject(result.titles);
            }
            if (result.practices && result.practices.length)
              this.practiceGroupItemsStream = new BehaviorSubject(result.practices);
            if (result.associateYears && result.associateYears.length)
              this.associateYearItemsStream = new BehaviorSubject(result.associateYears);
            if (result.srAssociateYears && result.associateYears.length)
              this.srAssociateYearItemsStream = new BehaviorSubject(result.srAssociateYears);
            if (result.partnerYears && result.associateYears.length)
              this.partnerYearItemsStream = new BehaviorSubject(result.partnerYears);
            if (result.rollingFormats && result.rollingFormats.length) this.populateTimeRange(result.rollingFormats);
            // For custimize
            const isCustomRequest = this.peercheckMessageHandlerService.getIsCustomRequest;
            const isCopyEditRequest = this.peercheckMessageHandlerService.getIsCopyEditRequest;
            let selectedMetricsIds = [];
            let selectedPeerGroupIds = [];
            let selectedPracticesIds = [];
            let selectedOfficesIds = [];
            let selectedTitlesIds = [];
            let selectedPartnerYearsIds = [];
            let selectedSrAssociateYearsYearsIds = [];
            let selectedAssociateYearsYearsIds = [];
            if (isCustomRequest || isCopyEditRequest) {
              let viewDetails = this.peercheckMessageHandlerService.getViewDetails;

              if (viewDetails.metrics && viewDetails.metrics.length) {
                selectedMetricsIds = [...new Set(viewDetails.metrics.map((item) => item.id))];
              }
              if (viewDetails.peerGroups && viewDetails.peerGroups.length) {
                selectedPeerGroupIds = [...new Set(viewDetails.peerGroups.map((item) => item.id))];
              }
              if (viewDetails.practices && viewDetails.practices.length) {
                selectedPracticesIds = [...new Set(viewDetails.practices.map((item) => item.id))];
              }
              if (viewDetails.offices && viewDetails.offices.length) {
                selectedOfficesIds = [...new Set(viewDetails.offices.map((item) => item.id))];
              }
              if (viewDetails.titles && viewDetails.titles.length) {
                selectedTitlesIds = [...new Set(viewDetails.titles.map((item) => item.id))];
              }
              if (viewDetails.partnerExpYears && viewDetails.partnerExpYears.length) {
                selectedPartnerYearsIds = [...new Set(viewDetails.partnerExpYears.map((item) => item.id))];
              }
              if (viewDetails.srAssociateExpYears && viewDetails.srAssociateExpYears.length) {
                selectedSrAssociateYearsYearsIds = [...new Set(viewDetails.srAssociateExpYears.map((item) => item.id))];
              }
              if (viewDetails.associateExpYears && viewDetails.associateExpYears.length) {
                selectedAssociateYearsYearsIds = [...new Set(viewDetails.associateExpYears.map((item) => item.id))];
              }
            } else if (refreshFromPPG) {
              if (this.createViewsModel.metricSelectedItems && this.createViewsModel.metricSelectedItems.length) {
                selectedMetricsIds = [...new Set(this.createViewsModel.metricSelectedItems.map((item) => item.id))];
              }
              if (this.createViewsModel.peerGroupSelectedItems && this.createViewsModel.peerGroupSelectedItems.length) {
                selectedPeerGroupIds = [
                  ...new Set(this.createViewsModel.peerGroupSelectedItems.map((item) => item.id)),
                ];
              }
              if (
                this.createViewsModel.practiceGroupSelectedItems &&
                this.createViewsModel.practiceGroupSelectedItems.length
              ) {
                selectedPracticesIds = [
                  ...new Set(this.createViewsModel.practiceGroupSelectedItems.map((item) => item.id)),
                ];
              }
              if (this.createViewsModel.officeSelectedItems && this.createViewsModel.officeSelectedItems.length) {
                selectedOfficesIds = [...new Set(this.createViewsModel.officeSelectedItems.map((item) => item.id))];
              }
              if (this.createViewsModel.titleSelectedItems && this.createViewsModel.titleSelectedItems.length) {
                selectedTitlesIds = [...new Set(this.createViewsModel.titleSelectedItems.map((item) => item.id))];
              }
              if (
                this.createViewsModel.partnerYearSelectedItems &&
                this.createViewsModel.partnerYearSelectedItems.length
              ) {
                selectedPartnerYearsIds = [
                  ...new Set(this.createViewsModel.partnerYearSelectedItems.map((item) => item.id)),
                ];
              }
              if (
                this.createViewsModel.srAssociateYearSelectedItems &&
                this.createViewsModel.srAssociateYearSelectedItems.length
              ) {
                selectedSrAssociateYearsYearsIds = [
                  ...new Set(this.createViewsModel.srAssociateYearSelectedItems.map((item) => item.id)),
                ];
              }
              if (
                this.createViewsModel.associateYearSelectedItems &&
                this.createViewsModel.associateYearSelectedItems.length
              ) {
                selectedAssociateYearsYearsIds = [
                  ...new Set(this.createViewsModel.associateYearSelectedItems.map((item) => item.id)),
                ];
              }
            }

            // TimeRange:
            if (this.viewDetails) {
              this.createViewsModel = this.peercheckMessageHandlerService.preSelectDataforTimeRangeAndSettings(
                this.viewDetails,
                this.createViewsModel
              );
            }

            // Metrics
            // this.createViewsModel.metricSelectedItems =
            //   result.metrics.filter((obj) => {
            //     return selectedMetricsIds.includes(obj.id);
            //   }) || null;
            let selectedMetricsArr = [];
            for (let i = 0; i < selectedMetricsIds.length; i++) {
              for (let j = 0; j < result.metrics.length; j++) {
                if (selectedMetricsIds[i] == result.metrics[j].id) {
                  selectedMetricsArr.push(result.metrics[j]);
                  break;
                }
              }
            }
            this.createViewsModel.metricSelectedItems = selectedMetricsArr;

            let groupMetric = this.metricItemsStream.value.filter((obj) => {
              return obj.groupId == null;
            });
            groupMetric.forEach((parent) => {
              let selectedChild = result.metrics.filter((obj) => {
                return selectedMetricsIds.includes(obj.id) && obj.groupId == parent.id;
              });
              let allChild = result.metrics.filter((obj) => {
                return obj.groupId == parent.id;
              });
              if (selectedChild.length == allChild.length) {
                this.createViewsModel.metricSelectedItems.push(parent);
              }
            });

            // PeerGroups
            this.createViewsModel.peerGroupSelectedItems =
              result.peerGroups.filter((obj) => {
                return selectedPeerGroupIds.includes(obj.id);
              }) || null;

            // Practices
            this.createViewsModel.practiceGroupSelectedItems =
              result.practices.filter((obj) => {
                return selectedPracticesIds.includes(obj.id);
              }) || null;

            // Offices
            this.createViewsModel.officeSelectedItems =
              result.offices.filter((obj) => {
                return selectedOfficesIds.includes(obj.id);
              }) || null;

            // Titles
            this.createViewsModel.titleSelectedItems =
              result.titles.filter((obj) => {
                return selectedTitlesIds.includes(obj.id);
              }) || null;

            // Partner Years
            this.createViewsModel.partnerYearSelectedItems =
              result.partnerYears.filter((obj) => {
                return selectedPartnerYearsIds.includes(obj.id);
              }) || null;
            this.createViewsModel.partnerYearSelectedText =
              this.createViewsModel.partnerYearSelectedItems && this.createViewsModel.partnerYearSelectedItems.length
                ? this.createViewsModel.partnerYearSelectedItems.length + ' selected'
                : 'Select';

            // Associate Year
            this.createViewsModel.associateYearSelectedItems =
              result.associateYears.filter((obj) => {
                return selectedAssociateYearsYearsIds.includes(obj.id);
              }) || null;

            // SrAssociate Year
            this.createViewsModel.srAssociateYearSelectedItems =
              result.srAssociateYears.filter((obj) => {
                return selectedSrAssociateYearsYearsIds.includes(obj.id);
              }) || null;

            this.isPartnerYear = false;
            this.isAssociateYear = false;
            this.isSrAssociateYear = false;
            this.createViewsModel.titleSelectedItems.forEach((title) => {
              if (this.allowedPartnerYearTitles.indexOf(title.id) > -1) this.isPartnerYear = true;
              if (this.allowedAssociateYearTitles.indexOf(title.id) > -1) this.isAssociateYear = true;
              if (this.allowedSrAssociateYearTitles.indexOf(title.id) > -1) this.isSrAssociateYear = true;
            });
            if (!this.isPartnerYear) this.createViewsModel.partnerYearSelectedItems = [];
            if (!this.isAssociateYear) this.createViewsModel.associateYearSelectedItems = [];
            if (!this.isSrAssociateYear) this.createViewsModel.srAssociateYearSelectedItems = [];

            this.updateSelectedText();

            let requestOrigin = {};
            requestOrigin['requestFrom'] = 'populateInputValuesForCustomize';
            if (
              ((this.createViewsModel.timeRangeSelected == 'Rolling' &&
                this.createViewsModel.rollingTimeRangeSelectedItems) ||
                (this.createViewsModel.timeRangeSelected == 'Fixed' &&
                  this.createViewsModel.fixedTimeRange.startDateText &&
                  this.createViewsModel.fixedTimeRange.endDateText) ||
                this.createViewsModel.timeRangeSelected == 'Year To Date(YTD)') &&
              this.createViewsModel.metricSelectedItems.length > 0 &&
              this.createViewsModel.peerGroupSelectedItems.length > 0
            ) {
              this.disableDropdowns = false;
              if (!refreshFromPPG) this.viewGridComponent.updateSelection(requestOrigin, false);
            }
          }
          this.peercheckMessageHandlerService.setIsCustomRequest = false;
          this.isInputPanelDataLoading = false;
        },
        (error) => {
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
          this.isDataLoading = false;
          this.isInputPanelDataLoading = false;
        }
      );
  }

  onDragDone(indexObj) {
    const {dragIndex, dropIndex, metricIdArr} = indexObj;
    this.metricReorderIdsArr = metricIdArr;

    if (dragIndex && dropIndex) {
      const selectedArrWithNullGroupId = this.createViewsModel.metricSelectedItems.filter(
        (item) => item.groupName == null
      );

      const selectedMetricArr = this.createViewsModel.metricSelectedItems.filter((item) => item.groupName != null);
      let item = selectedMetricArr[dragIndex];
      selectedMetricArr.splice(dragIndex, 1);
      selectedMetricArr.splice(dropIndex, 0, item);
      for (let i = 0; i < selectedArrWithNullGroupId.length; i++) {
        selectedMetricArr.push(selectedArrWithNullGroupId[i]);
      }

      this.createViewsModel.metricSelectedItems = selectedMetricArr;
    }
  }

  updateSelectedText() {
    if (this.createViewsModel.metricSelectedItems && this.createViewsModel.metricSelectedItems.length) {
      let selectedMetrics = this.createViewsModel.metricSelectedItems.filter((obj) => {
        return obj.groupId != null;
      });
      if (selectedMetrics && selectedMetrics.length == 1)
        this.createViewsModel.metricSelectedText = selectedMetrics.length + ' metric selected';
      else this.createViewsModel.metricSelectedText = selectedMetrics.length + ' metrics selected';
    } else this.createViewsModel.metricSelectedText = 'Select';

    if (this.createViewsModel.peerGroupSelectedItems && this.createViewsModel.peerGroupSelectedItems.length)
      if (this.createViewsModel.peerGroupSelectedItems.length == 1)
        this.createViewsModel.peerGroupSelectedText =
          this.createViewsModel.peerGroupSelectedItems.length + ' peer group selected';
      else
        this.createViewsModel.peerGroupSelectedText =
          this.createViewsModel.peerGroupSelectedItems.length + ' peer groups selected';
    else this.createViewsModel.peerGroupSelectedText = 'Select';

    if (this.createViewsModel.practiceGroupSelectedItems && this.createViewsModel.practiceGroupSelectedItems.length)
      if (this.createViewsModel.practiceGroupSelectedItems.length == 1)
        this.createViewsModel.practiceGroupSelectedText =
          this.createViewsModel.practiceGroupSelectedItems.length + ' practice group selected';
      else
        this.createViewsModel.practiceGroupSelectedText =
          this.createViewsModel.practiceGroupSelectedItems.length + ' practice groups selected';
    else this.createViewsModel.practiceGroupSelectedText = 'Select';

    if (this.createViewsModel.officeSelectedItems && this.createViewsModel.officeSelectedItems.length)
      if (this.createViewsModel.officeSelectedItems.length == 1)
        this.createViewsModel.officeSelectedText =
          this.createViewsModel.officeSelectedItems.length + ' office selected';
      else
        this.createViewsModel.officeSelectedText =
          this.createViewsModel.officeSelectedItems.length + ' offices selected';
    else this.createViewsModel.officeSelectedText = 'Select';

    if (this.createViewsModel.titleSelectedItems && this.createViewsModel.titleSelectedItems.length)
      if (this.createViewsModel.titleSelectedItems.length == 1)
        this.createViewsModel.titleSelectedText = this.createViewsModel.titleSelectedItems.length + ' title selected';
      else
        this.createViewsModel.titleSelectedText = this.createViewsModel.titleSelectedItems.length + ' titles selected';
    else this.createViewsModel.titleSelectedText = 'Select';

    if (this.createViewsModel.partnerYearSelectedItems && this.createViewsModel.partnerYearSelectedItems.length)
      if (this.createViewsModel.partnerYearSelectedItems.length == 1)
        this.createViewsModel.partnerYearSelectedText =
          this.createViewsModel.partnerYearSelectedItems.length + ' experience year selected ';
      else
        this.createViewsModel.partnerYearSelectedText =
          this.createViewsModel.partnerYearSelectedItems.length + ' experiences year selected ';
    else this.createViewsModel.partnerYearSelectedText = 'Select';

    if (this.createViewsModel.associateYearSelectedItems && this.createViewsModel.associateYearSelectedItems.length)
      if (this.createViewsModel.associateYearSelectedItems.length == 1)
        this.createViewsModel.associateYearSelectedText =
          this.createViewsModel.associateYearSelectedItems.length + ' experience year selected ';
      else
        this.createViewsModel.associateYearSelectedText =
          this.createViewsModel.associateYearSelectedItems.length + ' experiences year selected ';
    else this.createViewsModel.associateYearSelectedText = 'Select';

    if (this.createViewsModel.srAssociateYearSelectedItems && this.createViewsModel.srAssociateYearSelectedItems.length)
      if (this.createViewsModel.srAssociateYearSelectedItems.length == 1)
        this.createViewsModel.srAssociateYearSelectedText =
          this.createViewsModel.srAssociateYearSelectedItems.length + ' experience year selected ';
      else
        this.createViewsModel.srAssociateYearSelectedText =
          this.createViewsModel.srAssociateYearSelectedItems.length + ' experiences year selected ';
    else this.createViewsModel.srAssociateYearSelectedText = 'Select';
  }

  metricOnItemsSelected(v: any[]) {
    if (!v) {
      return;
    }
    if (v.length) {
      let hasChanges = JSON.stringify(this.createViewsModel.metricSelectedItems) != JSON.stringify(v);
      let result = v.filter((item) => item.groupId != null);
      this.createViewsModel.metricSelectedItems = v;
      if (result.length == 1) this.createViewsModel.metricSelectedText = result.length.toString() + ' metric selected';
      else this.createViewsModel.metricSelectedText = result.length.toString() + ' metrics selected';
      if (
        ((this.createViewsModel.timeRangeSelected == 'Rolling' &&
          this.createViewsModel.rollingTimeRangeSelectedItems &&
          this.createViewsModel.rollingTimeRangeSelectedItems.name) ||
          (this.createViewsModel.timeRangeSelected == 'Fixed' &&
            this.createViewForm &&
            this.createViewForm.form &&
            this.createViewForm.form.controls.startDate.valid &&
            this.createViewForm.form.controls.endDate.valid) ||
          this.createViewsModel.timeRangeSelected == 'Year To Date(YTD)') &&
        this.createViewsModel.peerGroupSelectedItems &&
        this.createViewsModel.peerGroupSelectedItems.length
      ) {
        this.disableDropdowns = false;
        let requestOrigin = {};
        requestOrigin['requestFrom'] = 'metricOnItemsSelected';
        if (hasChanges) {
          this.viewGridComponent.updateSelection(requestOrigin, true);
        }
      }
    } else {
      this.disableDropdowns = true;
      this.createViewsModel.metricSelectedItems = [];
    }
  }

  peerGroupOnItemsSelected(v: any[]) {
    if (!v) {
      return;
    }
    if (v.length > 0 && v.length <= 4) {
      let hasChanges = JSON.stringify(this.createViewsModel.peerGroupSelectedItems) != JSON.stringify(v);
      this.createViewsModel.peerGroupSelectedItems = v;
      if (v.length == 1) this.createViewsModel.peerGroupSelectedText = v.length.toString() + ' peer group selected';
      else this.createViewsModel.peerGroupSelectedText = v.length.toString() + ' peer groups selected';
      if (
        ((this.createViewsModel.timeRangeSelected == 'Rolling' &&
          this.createViewsModel.rollingTimeRangeSelectedItems &&
          this.createViewsModel.rollingTimeRangeSelectedItems.name) ||
          (this.createViewsModel.timeRangeSelected == 'Fixed' &&
            this.createViewForm &&
            this.createViewForm.form &&
            this.createViewForm.form.controls.startDate.valid &&
            this.createViewForm.form.controls.endDate.valid) ||
          this.createViewsModel.timeRangeSelected == 'Year To Date(YTD)') &&
        this.createViewsModel.metricSelectedItems &&
        this.createViewsModel.metricSelectedItems.length
      ) {
        this.disableDropdowns = false;
        let requestOrigin = {};
        requestOrigin['requestFrom'] = 'peerGroupOnItemsSelected';
        if (hasChanges) {
          this.viewGridComponent.updateSelection(requestOrigin, true);
        }
      }
    } else {
      this.disableDropdowns = true;
      this.createViewsModel.peerGroupSelectedItems = [];
    }
  }

  practiceGroupOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'practiceGroupOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createViewsModel.practiceGroupSelectedItems) != JSON.stringify(v);
      this.createViewsModel.practiceGroupSelectedItems = v;
      if (v.length == 1)
        this.createViewsModel.practiceGroupSelectedText = v.length.toString() + ' practice group selected';
      else this.createViewsModel.practiceGroupSelectedText = v.length.toString() + ' practice groups selected';
    } else {
      hasChanges = JSON.stringify(this.createViewsModel.practiceGroupSelectedItems) != JSON.stringify(v);
      this.createViewsModel.practiceGroupSelectedItems = [];
    }
    if (hasChanges) {
      this.viewGridComponent.updateSelection(requestOrigin, true);
    }
  }

  officeOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'officeOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createViewsModel.officeSelectedItems) != JSON.stringify(v);
      this.createViewsModel.officeSelectedItems = v;
      if (v.length == 1) this.createViewsModel.officeSelectedText = v.length.toString() + ' office selected';
      else this.createViewsModel.officeSelectedText = v.length.toString() + ' offices selected';
    } else {
      hasChanges = JSON.stringify(this.createViewsModel.officeSelectedItems) != JSON.stringify(v);
      this.createViewsModel.officeSelectedItems = [];
    }
    if (hasChanges) {
      this.viewGridComponent.updateSelection(requestOrigin, true);
    }
  }

  titleOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'titleOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createViewsModel.titleSelectedItems) != JSON.stringify(v);
      this.createViewsModel.titleSelectedItems = v;
      if (v.length == 1) this.createViewsModel.titleSelectedText = v.length.toString() + ' title selected';
      else this.createViewsModel.titleSelectedText = v.length.toString() + ' titles selected';
      this.isPartnerYear = false;
      this.isAssociateYear = false;
      this.isSrAssociateYear = false;
      this.createViewsModel.titleSelectedItems.forEach((title) => {
        if (this.allowedPartnerYearTitles.indexOf(title.id) > -1) this.isPartnerYear = true;
        if (this.allowedAssociateYearTitles.indexOf(title.id) > -1) this.isAssociateYear = true;
        if (this.allowedSrAssociateYearTitles.indexOf(title.id) > -1) this.isSrAssociateYear = true;
      });
      if (!this.isPartnerYear) this.createViewsModel.partnerYearSelectedItems = [];
      if (!this.isAssociateYear) this.createViewsModel.associateYearSelectedItems = [];
      if (!this.isSrAssociateYear) this.createViewsModel.srAssociateYearSelectedItems = [];
    } else {
      hasChanges = JSON.stringify(this.createViewsModel.titleSelectedItems) != JSON.stringify(v);
      this.isPartnerYear = false;
      this.createViewsModel.partnerYearSelectedItems = [];
      this.isAssociateYear = false;
      this.createViewsModel.associateYearSelectedItems = [];
      this.isSrAssociateYear = false;
      this.createViewsModel.srAssociateYearSelectedItems = [];
      this.createViewsModel.titleSelectedItems = [];
    }
    if (hasChanges) {
      this.viewGridComponent.updateSelection(requestOrigin, true);
    }
  }

  associateYearOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'associateYearOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createViewsModel.associateYearSelectedItems) != JSON.stringify(v);
      this.createViewsModel.associateYearSelectedItems = v;
      if (v.length == 1)
        this.createViewsModel.associateYearSelectedText = v.length.toString() + ' experience year selected ';
      else this.createViewsModel.associateYearSelectedText = v.length.toString() + ' experience years selected ';
    } else {
      hasChanges = JSON.stringify(this.createViewsModel.associateYearSelectedItems) != JSON.stringify(v);
      this.createViewsModel.associateYearSelectedItems = [];
    }
    if (hasChanges) {
      this.viewGridComponent.updateSelection(requestOrigin, true);
    }
  }

  srAssociateYearOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'srAssociateYearOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createViewsModel.srAssociateYearSelectedItems) != JSON.stringify(v);
      this.createViewsModel.srAssociateYearSelectedItems = v;
      if (v.length == 1)
        this.createViewsModel.srAssociateYearSelectedText = v.length.toString() + ' experience year selected ';
      else this.createViewsModel.srAssociateYearSelectedText = v.length.toString() + ' experience years selected ';
    } else {
      hasChanges = JSON.stringify(this.createViewsModel.srAssociateYearSelectedItems) != JSON.stringify(v);
      this.createViewsModel.srAssociateYearSelectedItems = [];
    }
    if (hasChanges) {
      this.viewGridComponent.updateSelection(requestOrigin, true);
    }
  }

  partnerYearOnItemsSelected(v: any[]) {
    let requestOrigin = {};
    requestOrigin['requestFrom'] = 'partnerYearOnItemsSelected';
    let hasChanges = false;
    if (!v) {
      return;
    }
    if (v.length) {
      hasChanges = JSON.stringify(this.createViewsModel.partnerYearSelectedItems) != JSON.stringify(v);
      this.createViewsModel.partnerYearSelectedItems = v;
      if (v.length == 1)
        this.createViewsModel.partnerYearSelectedText = v.length.toString() + ' experience year selected ';
      else this.createViewsModel.partnerYearSelectedText = v.length.toString() + ' experience years selected ';
    } else {
      hasChanges = JSON.stringify(this.createViewsModel.partnerYearSelectedItems) != JSON.stringify(v);
      this.createViewsModel.partnerYearSelectedItems = [];
    }
    if (hasChanges) {
      this.viewGridComponent.updateSelection(requestOrigin, true);
    }
  }

  timeRangeOnItemsSelected(v: any) {
    if (v && v.id == 0) {
      this.createViewsModel.timeRangeSelected = 'Year To Date(YTD)';
      this.createViewsModel.rollingTimeRangeSelectedItems = v;
      let requestOrigin = {};
      requestOrigin['requestFrom'] = 'timeRangeOnItemsSelected';
      if (
        this.createViewsModel.metricSelectedItems.length > 0 &&
        this.createViewsModel.peerGroupSelectedItems.length > 0
      ) {
        this.viewGridComponent.updateSelection(requestOrigin, true);
      }
    } else {
      if (v) {
        this.createViewsModel.timeRangeSelected = 'Rolling';
        this.createViewsModel.rollingTimeRangeSelectedItems = v;
        if (
          this.createViewsModel &&
          this.createViewsModel.metricSelectedItems &&
          this.createViewsModel.metricSelectedItems.length &&
          this.createViewsModel.peerGroupSelectedItems &&
          this.createViewsModel.peerGroupSelectedItems.length
        ) {
          this.disableDropdowns = false;
          let requestOrigin = {};
          requestOrigin['requestFrom'] = 'timeRangeOnItemsSelected';
          this.viewGridComponent.updateSelection(requestOrigin, true);
        }
      } else {
        this.clearAll();
      }
    }
  }

  openSaveView() {
    let requestBody = this.createRequestBodyForSaveView();
    let saveViewModel: SaveView = new SaveView();
    if (this.isCopyEditRequest) {
      saveViewModel.viewId = this.viewDetails.viewId;
      saveViewModel.viewName = this.viewDetails.viewName;
      saveViewModel.original_viewName = this.viewDetails.viewName;
      saveViewModel.isSharedView = this.isSharedView;
    }
    let performancePeerGroups = this.createViewsModel.peerGroupSelectedItems.filter((pg) => (pg.isPpg ? true : false));
    saveViewModel.isContainsPPG = performancePeerGroups && performancePeerGroups.length > 0;

    const metricNames = this.createViewsModel.metricSelectedItems
      .filter((item) => item.groupId != null)
      .map(({name}) => name);
    const peerGroupNames = this.createViewsModel.peerGroupSelectedItems.map(({name}) => name);
    const practiceGroupNames = this.createViewsModel.practiceGroupSelectedItems.map(({name}) => name);
    const officeNames = this.createViewsModel.officeSelectedItems.map(({name}) => name);
    const titleNames = this.createViewsModel.titleSelectedItems.map(({name}) => name);
    let dateRange, timeRangeType, startPeriod, endPeriod;
    if (this.createViewsModel.timeRangeSelected == 'Rolling' && this.createViewsModel.rollingTimeRangeSelectedItems) {
      dateRange = 'Rolling';
      timeRangeType = this.createViewsModel.rollingTimeRangeSelectedItems.name;
      startPeriod = +this.createViewsModel.rollingTimeRangeSelectedItems.startPeriod1;
      endPeriod = +this.createViewsModel.rollingTimeRangeSelectedItems.endPeriod1;
    } else if (this.createViewsModel.timeRangeSelected == 'Fixed' && this.createViewsModel.fixedTimeRange) {
      dateRange = 'Fixed';
      startPeriod = +this.createViewsModel.fixedTimeRange.startPeriod1;
      endPeriod = +this.createViewsModel.fixedTimeRange.endPeriod1;
    } else if (this.createViewsModel.timeRangeSelected == 'Year To Date(YTD)') {
      dateRange = 'Year To Date(YTD)';
      const date = new Date();
      date.setDate(1);
      const month = '01';

      startPeriod = date.getFullYear() + month;

      const endMonth = ('0' + (date.getMonth())+1).slice(-2);

      endPeriod = date.getFullYear() + endMonth;
      startPeriod = Number(startPeriod);
      endPeriod = Number(endPeriod);
    }

    let metadata = Object.assign(
      {},
      metricNames && metricNames.length > 0 && {metricNames},
      peerGroupNames && peerGroupNames.length > 0 && {peerGroupNames},
      practiceGroupNames && practiceGroupNames.length > 0 && {practiceGroupNames},
      officeNames && officeNames.length > 0 && {officeNames},
      titleNames && titleNames.length > 0 && {titleNames},
      dateRange && {dateRange},
      timeRangeType && {timeRangeType},
      startPeriod && {startPeriod},
      endPeriod && {endPeriod}
    );

    const modalRef = this.modalService.open(SaveViewComponent, {ariaLabelledBy: 'modalSaveView'});
    modalRef.componentInstance.settingFrom = 'Create a view';
    modalRef.componentInstance.persistSaveViewModel = saveViewModel;
    modalRef.componentInstance.viewData = requestBody;
    modalRef.componentInstance.firmID = this.getDefaultOrSelectedFirmId;
    modalRef.componentInstance.viewDetails = this.viewDetails;
    modalRef.componentInstance.metadata = metadata;
    setTimeout(function () {
      $('#viewName').focus();
    }, 0);
    modalRef.result.then(
      (result) => {
        if (result == 'Success') {
          this.alerts.push({
            type: 'success',
            msg: 'View saved successfully.',
            timeout: 2500,
            closeable: true,
          });
        } else {
          this.alerts.push({
            type: 'warning',
            msg: result,
            closeable: true,
          });
        }
      }
    );
  }

  collapseAll() {
    this.createViewsModel.cardTitles.forEach((card) => {
      card.isCollapsed = true;
    });
  }

  expandAll() {
    this.createViewsModel.cardTitles.forEach((card) => {
      card.isCollapsed = false;
    });
  }

  clearAll() {
    this.isInputPanelDataLoading = true;
    setTimeout(() => {
      this.createViewsModel.fixedTimeRange = new FixedTimeRange();
      this.createViewsModel.rollingTimeRangeSelectedItems = null;
      this.createViewsModel.cardTitles = [];
      this.createViewsModel.metricSelectedItems = [];
      this.createViewsModel.peerGroupSelectedItems = [];
      this.createViewsModel.practiceGroupSelectedItems = [];
      this.createViewsModel.officeSelectedItems = [];
      this.createViewsModel.titleSelectedItems = [];
      this.createViewsModel.associateYearSelectedItems = [];
      this.createViewsModel.srAssociateYearSelectedItems = [];
      this.createViewsModel.partnerYearSelectedItems = [];
      this.isPartnerYear = false;
      this.isAssociateYear = false;
      this.isSrAssociateYear = false;
      this.createViewsModel.descriptionList = [];
      this.disableDropdowns = true;
      this.createViewsModel.timeRangeSelected = this.selectedTimeRange;
      this.isInputPanelDataLoading = false;
      if (this.peercheckMessageHandlerService) {
        this.processedMainGridData = this.peercheckMessageHandlerService.processDataForMainGrid(
          this.emptyGridObj,
          this.createViewsModel
        );
        this.viewGridComponent.resetToDefault(this.processedMainGridData);
      }
    }, 5);
  }

  isValidDate(evt: BfmFieldEvent, sourceElement: string, formData: any): void {
    if (!evt) {
      return;
    }
    const setValidity = evt.setValidity;
    if (
      (sourceElement == 'EndDate' && formData.form.controls.startDate.value) ||
      (sourceElement == 'StartDate' && formData.form.controls.endDate.value)
    ) {
      const fg = evt.formControl;

      let endDate = formData.form.controls.endDate.value;
      let startDate = formData.form.controls.startDate.value;
      let startDateMatch = startDate.match(this.validatePattern);
      let endDateMatch = endDate.match(this.validatePattern);
      let isBeyondEndDateExtractDate = this.checkEndDateWithExtractDate(endDateMatch);
      if (
        !(
          startDateMatch &&
          endDateMatch &&
          (Number(startDateMatch[2]) < Number(endDateMatch[2]) ||
            (Number(startDateMatch[2]) == Number(endDateMatch[2]) &&
              Number(startDateMatch[1]) <= Number(endDateMatch[1])))
        )
      ) {
        setTimeout(() => {
          return setValidity({valid: false, message: 'Start Date should be less than End Date'});
        }, 0);
      } else {
        if (sourceElement == 'EndDate' && isBeyondEndDateExtractDate) {
          formData.form.get('startDate').updateValueAndValidity();
          setTimeout(() => {
            return setValidity({
              valid: false,
              message: `End date cannot be beyond ${this.extractedYYYYMM.month}/${this.extractedYYYYMM.year}.`,
            });
          }, 0);
        } else {
          setValidity({valid: true});
          let period = this.peercheckMessageHandlerService.getPeriod(
            this.createViewsModel,
            null,
            null,
            false,
            startDateMatch,
            endDateMatch
          );
          this.createViewsModel.fixedTimeRange.startPeriod1 = period[0];
          this.createViewsModel.fixedTimeRange.endPeriod1 = period[1];
          this.createViewForm = formData;
          if (sourceElement == 'EndDate' && formData.form.controls.startDate.status == 'INVALID') {
            return formData.form.get('startDate').updateValueAndValidity();
          } else if (sourceElement == 'StartDate' && formData.form.controls.endDate.status == 'INVALID') {
            return formData.form.get('endDate').updateValueAndValidity();
          }
        }
      }
    } else {
      if (sourceElement == 'EndDate' && formData.form.controls.endDate.value) {
        let endDate = formData.form.controls.endDate.value;
        let endDateMatch = endDate.match(this.validatePattern);
        let isBeyondEndDateExtractDate = this.checkEndDateWithExtractDate(endDateMatch);
        if (isBeyondEndDateExtractDate) {
          formData.form.get('startDate').updateValueAndValidity();
          setTimeout(() => {
            return setValidity({
              valid: false,
              message: `End date cannot be beyond ${this.extractedYYYYMM.month}/${this.extractedYYYYMM.year}.`,
            });
          }, 0);
        }
        return;
      } else {
        return;
      }
    }
  }

  populateTimeRange(rollingTimeRange) {
    let periods = [];
    let currentDate = new Date();
    rollingTimeRange.forEach((element) => {
      currentDate = new Date();
      if (this.firmBasicData.yyyymm) {
        let yyyymm = this.firmBasicData.yyyymm.toString();
        let yyyymmAry = yyyymm.replace(/(.{4})/g, '$1-').split('-');
        currentDate.setFullYear(Number(yyyymmAry[0]));
        currentDate.setDate(1);
        currentDate.setMonth(Number(yyyymmAry[1]) - 1);
      }
      periods = this.peercheckMessageHandlerService.getPeriod(
        this.createViewsModel,
        currentDate,
        this.peercheckMessageHandlerService.getMonthForTimeRange(element.name),
        true,
        null,
        null
      );
      element['startPeriod1'] = periods[1];
      element['endPeriod1'] = periods[0];
      if (
        (this.isCopyEditRequest || this.isCustomRequest) &&
        this.viewDetails &&
        this.viewDetails.rollingFormat &&
        this.viewDetails.rollingFormat.id == element.id
      ) {
        this.createViewsModel.rollingTimeRangeSelectedItems = element;
      }
    });

    this.rollingTimeRangeItemsStream = new BehaviorSubject(rollingTimeRange);
  }

  getMetricGroup(metrics: any[]) {
    let metricGroups = metrics
      .map(({groupId, groupName}) => ({groupId, groupName}))
      .filter((item, i, arr) => arr.findIndex((t) => t.groupId === item.groupId) === i);
    let finalMetric = [];
    metricGroups.forEach((mg, index) => {
      finalMetric.push({
        id: mg.groupId,
        groupId: null,
        groupName: null,
        name: mg.groupName,
        isIndeterminate: false,
      });
      let result = metrics.filter((item) => item.groupName === mg.groupName);
      finalMetric = finalMetric.concat(result);
    });
    return finalMetric;
  }

  handleFixedDateRangeSelection() {
    if (
      !(
        this.createViewsModel &&
        this.createViewsModel.fixedTimeRange &&
        this.createViewsModel.fixedTimeRange.startDateText &&
        this.createViewsModel.fixedTimeRange.endDateText
      )
    ) {
      return;
    }

    let startDateText = this.createViewsModel.fixedTimeRange.startDateText;
    let endDateText = this.createViewsModel.fixedTimeRange.endDateText;

    if (
      this.createViewForm &&
      this.createViewForm.form &&
      this.createViewForm.form.controls.startDate.valid &&
      this.createViewForm.form.controls.endDate.valid
    ) {
      let startDateMatch = startDateText.match(this.validatePattern);
      let endDateMatch = endDateText.match(this.validatePattern);

      let period = this.peercheckMessageHandlerService.getPeriod(
        this.createViewsModel,
        null,
        null,
        false,
        startDateMatch,
        endDateMatch
      );

      if (
        Number(period[0]) <= Number(period[1]) &&
        Number(period[1]) <= this.firmBasicData.yyyymm &&
        this.createViewsModel.metricSelectedItems &&
        this.createViewsModel.peerGroupSelectedItems &&
        this.createViewsModel.metricSelectedItems.length &&
        this.createViewsModel.peerGroupSelectedItems.length
      ) {
        this.createViewsModel.fixedTimeRange.startPeriod1 = period[0];
        this.createViewsModel.fixedTimeRange.endPeriod1 = period[1];
        this.viewGridComponent.updateSelection();
      }
    }
  }

  onDateRangeChange() {
    if (this.createViewsModel.isRollingTimeRange) {
      this.createViewsModel.timeRangeSelected = 'Rolling';
    } else {
      this.createViewsModel.timeRangeSelected = 'Fixed';
    }
  }

  createRequestBodyForSaveView() {
    let requestbody = {};
    if (this.createViewsModel.timeRangeSelected == 'Rolling' && this.createViewsModel.rollingTimeRangeSelectedItems) {
      let stDateAry = this.createViewsModel.rollingTimeRangeSelectedItems.startPeriod1
        .replace(/(.{4})/g, '$1-')
        .split('-');
      let edDateAry = this.createViewsModel.rollingTimeRangeSelectedItems.endPeriod1
        .replace(/(.{4})/g, '$1-')
        .split('-');

      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();

      firstDay_startDate.setFullYear(Number(stDateAry[0]));
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(Number(stDateAry[1]) - 1);

      lastDay_endDate.setDate(1);
      lastDay_endDate.setFullYear(Number(edDateAry[0]));
      lastDay_endDate.setMonth(Number(edDateAry[1]));
      lastDay_endDate.setDate(0);
     

      requestbody['rollingPeriodId'] = this.createViewsModel.rollingTimeRangeSelectedItems.id;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + (firstDay_startDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else if (
      this.createViewsModel.timeRangeSelected == 'Fixed' &&
      this.createViewsModel.fixedTimeRange &&
      this.createViewsModel.fixedTimeRange.startPeriod1 &&
      this.createViewsModel.fixedTimeRange.endPeriod1
    ) {
      let stDateAry = this.createViewsModel.fixedTimeRange.startPeriod1.replace(/(.{4})/g, '$1-').split('-');
      let edDateAry = this.createViewsModel.fixedTimeRange.endPeriod1.replace(/(.{4})/g, '$1-').split('-');

      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();

      firstDay_startDate.setDate(1);
      firstDay_startDate.setFullYear(Number(stDateAry[0]));
      firstDay_startDate.setMonth(Number(stDateAry[1]) - 1);

      lastDay_endDate.setDate(1);
      lastDay_endDate.setFullYear(Number(edDateAry[0]));
      lastDay_endDate.setMonth(Number(edDateAry[1]));
      lastDay_endDate.setDate(0);
     

      requestbody['rollingPeriodId'] = 9;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + (firstDay_startDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else if (this.createViewsModel.timeRangeSelected == 'Year To Date(YTD)') {
      const year = this.firmBasicData.yyyymm.toString().slice(0, 4);
      const month = this.firmBasicData.yyyymm.toString().slice(-2);
      let firstDay_startDate = new Date();
      let lastDay_endDate = new Date();
      firstDay_startDate.setDate(1);
      firstDay_startDate.setMonth(1);
      firstDay_startDate.setFullYear(parseInt(year));
      lastDay_endDate.setDate(1);
      lastDay_endDate.setMonth(parseInt(month));
      lastDay_endDate.setFullYear(parseInt(year));
      lastDay_endDate.setDate(0);
      requestbody['rollingPeriodId'] = this.createViewsModel.rollingTimeRangeSelectedItems.id;
      requestbody['startPeriod'] =
        firstDay_startDate.getFullYear() +
        '-' +
        ('0' + firstDay_startDate.getMonth()).toString().slice(-2) +
        '-' +
        ('0' + firstDay_startDate.getDate()).toString().slice(-2);
      requestbody['endPeriod'] =
        lastDay_endDate.getFullYear() +
        '-' +
        ('0' + (lastDay_endDate.getMonth() + 1)).toString().slice(-2) +
        '-' +
        ('0' + lastDay_endDate.getDate()).toString().slice(-2);
    } else {
      requestbody['rollingPeriodId'] = null;
      requestbody['startPeriod'] = null;
      requestbody['endPeriod'] = null;
    }

    if (this.metricReorderIdsArr.length > 0 && this.metricReorderIdsArr[0] != undefined) {
      requestbody['metrics'] = this.metricReorderIdsArr;
    } else if (this.createViewsModel.metricSelectedItems) {
      let result = this.createViewsModel.metricSelectedItems.filter((item) => item.groupId != null);
      let metrics: number[] = [...new Set(result.map((item) => item.id))];
      requestbody['metrics'] = metrics;
    } else {
      requestbody['metrics'] = [];
    }

    if (this.createViewsModel.peerGroupSelectedItems) {
      let peerGroups: number[] = [...new Set(this.createViewsModel.peerGroupSelectedItems.map((item) => item.id))];
      requestbody['peerGroups'] = peerGroups;
    } else {
      requestbody['peerGroups'] = [];
    }

    if (this.createViewsModel.practiceGroupSelectedItems) {
      let practices: number[] = [...new Set(this.createViewsModel.practiceGroupSelectedItems.map((item) => item.id))];
      requestbody['practices'] = practices;
    } else {
      requestbody['practices'] = [];
    }

    if (this.createViewsModel.officeSelectedItems) {
      let offices: number[] = [...new Set(this.createViewsModel.officeSelectedItems.map((item) => item.id))];
      requestbody['offices'] = offices;
    } else {
      requestbody['offices'] = [];
    }

    if (this.createViewsModel.titleSelectedItems) {
      let titles: number[] = [...new Set(this.createViewsModel.titleSelectedItems.map((item) => item.id))];
      requestbody['titles'] = titles;
    } else {
      requestbody['titles'] = [];
    }

    let experienceYears = {};

    if (this.createViewsModel.partnerYearSelectedItems) {
      let partnerYears: number[] = [...new Set(this.createViewsModel.partnerYearSelectedItems.map((item) => item.id))];
      experienceYears['partnerYears'] = partnerYears;
    } else {
      experienceYears['partnerYears'] = [];
    }

    if (this.createViewsModel.srAssociateYearSelectedItems) {
      let srAssociateYears: number[] = [
        ...new Set(this.createViewsModel.srAssociateYearSelectedItems.map((item) => item.id)),
      ];
      experienceYears['srAssociateYears'] = srAssociateYears;
    } else {
      experienceYears['srAssociateYears'] = [];
    }

    if (this.createViewsModel.srAssociateYearSelectedItems) {
      let associateYears: number[] = [
        ...new Set(this.createViewsModel.associateYearSelectedItems.map((item) => item.id)),
      ];
      experienceYears['associateYears'] = associateYears;
    } else {
      experienceYears['associateYears'] = [];
    }

    requestbody['experienceYears'] = experienceYears;

    requestbody['matterType'] = this.createViewsModel.viewsSettings.matterType.toLowerCase();
    requestbody['contractors'] = this.createViewsModel.viewsSettings.contractorsType.toLowerCase();
    requestbody['currency'] = this.createViewsModel.viewsSettings.currencyType.toLowerCase();
    requestbody['comparison'] = this.createViewsModel.viewsSettings.comparisonType.toLowerCase();
    requestbody['statistics'] = this.createViewsModel.viewsSettings.statisticsType.toLowerCase();

    return requestbody;
  }

  getViewDetails(viewId: string) {
    this.isDataLoading = true;
    this.service
      .get(
        environment.FIAdminBaseEndpoint + 'v1/view/' + viewId + '/firm/' + this.getDefaultOrSelectedFirmId + '/details',
        ''
      )
      .subscribe(
        (result) => {
          this.isDataLoading = false;
          this.viewDetails = result;

          this.peercheckMessageHandlerService.setViewDetails = this.viewDetails;
          this.populateInputValues();
        },
        (error) => {
          this.isDataLoading = false;
          this.errorMessage = error.error;
          this.alerts.push({
            type: 'warning',
            msg: 'Something went wrong, please try again.',
            closeable: true,
          });
        }
      );
  }

  onClickPPG() {
    this.openPPG = true;
  }

  retrieveCreateViewSelection(isPPGOpen) {
    this.openPPG = false;
    if (this.performancePeerGroups.isPPGDeleted || this.performancePeerGroups.isPPGSaved) {
      this.populateInputValues(true);
    }
  }

  dataLoadingStateChange(currentDataLoadingState) {
    this.isDataLoading = currentDataLoadingState;
  }
  onClickFilter(newValue: boolean) {
    if (this.isFilterPanelOpen === newValue) {
      this.isFilterPanelOpen = false;
      setTimeout(function () {
        $('.FilterPanel-body').hide();
      }, 500);
    } else {
      this.isFilterPanelOpen = newValue;
      $('.FilterPanel-body').show();
    }
  }
  getFilterPanelFeatureFlag() {
    /***** TO BE REMOVED PART OF FEATURE TOGGLE - EXPORT_MAPPINGS ******/
    /**Remove the call to feature API ***** */
    this.service
      .get(environment.FIAdminBaseEndpoint + 'v1/feature/CREATE_VIEW_FILTER_PANEL', '')
      .subscribe((result) => {
        this.filterPanelFlag = result['CREATE_VIEW_FILTER_PANEL']; // replace EXPORT_MAPPINGS with new toggle variable.
      });
  }
  monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth() + 1;
    return months <= 0 ? 0 : months;
  }

  checkEndDateWithExtractDate(endDateMatch: number[]) {
    if (endDateMatch) {
      let extractDate = new Date();
      let endDateFormat = new Date();
      endDateFormat.setFullYear(endDateMatch[2], endDateMatch[1], 1);
      extractDate.setFullYear(parseInt(this.extractedYYYYMM.year), parseInt(this.extractedYYYYMM.month), 1);
      return endDateFormat.getTime() > extractDate.getTime();
    }
    return false;
  }

  public get isTAdmin(): boolean {
    const backendTokenClaims: BackendTokenClaims = this.authService.getBackendTokenClaims();
    return backendTokenClaims && backendTokenClaims.userDDO && backendTokenClaims.userDDO.role === Role.TAdmin;
  }
}
